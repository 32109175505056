import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import PortfoliosDropdown from "../PortfoliosDropdown";
import CampaignsList from "./CampaignsListSd";
import DateRangePickerCustom from "../DateRangePickerCustom";
import SelectAccountDiv from "../SelectAccountDiv"
import { updateSelectedDate } from "../../store";

function CampaignsPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [portfolioId, setPortfolioId] = useState(0);
    const [buttonColor, setButtonColor] = useState(0);

    const dispatch = useDispatch()
    const selectedDate = useSelector(state => state.accounts.selectedDateCampaigns);
    const setSelectedDate = (selectedDate) => dispatch(updateSelectedDate(selectedDate))

    const dateNow = new Date();
    let start = new Date();
    let end = new Date();
    if (selectedDate) {
        start = new Date(selectedDate.endDate)
        end = new Date(selectedDate.startDate)
    } else {
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (2));
        end.setDate(dateNow.getDate() - (31));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
    }

    const [pickedDate, setPickedDate] = useState([
        {
            startDate: end,
            endDate: start,
            key: 'selection'
        }
    ]);
    console.log(pickedDate[0]);

    const onClick = (days) => {
        let start = new Date();
        let end = new Date();
        const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (2));
        end.setDate(dateNow.getDate() - (days + 1));
        start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
        end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
        setButtonColor(days)
        setPickedDate([{ startDate: end, endDate: start, key: "selection" }])
        setSelectedDate({ startDate: end.toString(), endDate: start.toString(), key: "selection" })
    }

    return (
        <div>
            {accountId
                ? <div className="flex flex-col h-screen">
                    <div className="flex px-5 py-1">
                        <PortfoliosDropdown setPortfolioId={setPortfolioId} />
                        <DateRangePickerCustom setSelectedDate={setSelectedDate} setPickedDate={setPickedDate} pickedDate={pickedDate} setButtonColor={setButtonColor} defaultText="Pick a Date"></DateRangePickerCustom>
                        <div className="text-xs">
                            {buttonColor === 7
                                ? <button onClick={() => onClick(7)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">7 Days</button>
                                : <button onClick={() => onClick(7)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">7 Days</button>
                            }

                            {buttonColor === 15
                                ? <button onClick={() => onClick(15)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">15 Days</button>
                                : <button onClick={() => onClick(15)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">15 Days</button>
                            }

                            {buttonColor === 30
                                ? <button onClick={() => onClick(30)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">30 Days</button>
                                : <button onClick={() => onClick(30)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">30 Days</button>
                            }

                            {buttonColor === 60
                                ? <button onClick={() => onClick(60)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">60 Days</button>
                                : <button onClick={() => onClick(60)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">60 Days</button>
                            }

                            {buttonColor === 90
                                ? <button onClick={() => onClick(90)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">90 Days</button>
                                : <button onClick={() => onClick(90)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">90 Days</button>
                            }
                        </div>
                    </div>
                    <CampaignsList portfolioId={portfolioId} pickedDate={pickedDate[0]} accountId={accountId} />
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }

        </div>
    );
}

export default CampaignsPage;