import { useMemo } from 'react';
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { GROUPED_COLUMNS } from './campaignsColumns';
import './campaignstable.css'
import { Checkbox } from '../Checkbox';
import CampaignTableBulkUpdate from './CampaignTableBulkUpdate';


export default function CampaignsTable({ data }) {
    const columns = useMemo(() => GROUPED_COLUMNS, []);
    const tableData = useMemo(() => data, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        toggleAllRowsSelected
    } = useTable({
        columns,
        data: tableData,
        initialState: {
            sortBy: [
                {
                    id: 'spend_1',
                    desc: true
                }
            ]
        },
        disableSortRemove: true,
    },
        useSortBy,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => <div className='flex justify-center p-1  sticky left-0'><Checkbox {...getToggleAllRowsSelectedProps()} /></div>,
                        Cell: ({ row }) => <div className='flex justify-center p-1'><Checkbox {...row.getToggleRowSelectedProps()} /></div>
                    },
                    ...columns
                ]
            })
        });

    const deselectAll = () => {
        toggleAllRowsSelected(false);
    };

    return (
        <div className=''>
            <div className='z-10 sticky top-0'>
                <CampaignTableBulkUpdate selectedFlatRows={selectedFlatRows} deselectAll={deselectAll} />
            </div>
            <div className='max-h-screen3/4 overflow-scroll rounded-xl border text-xs'>
                <table {...getTableProps()} className='campaign' >
                    <thead className='sticky top-0 drop-shadow z-10'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="cursor-pointer hover:bg-sky-900 select-none" >
                                        <div className='flex items-center justify-center'>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (column.isSortedDesc ? <GoChevronDown /> : <GoChevronUp />) : ''}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}  >
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} >
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()} >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot className='sticky bottom-0 drop-shadow z-10'>
                        {footerGroups.map(footerGroup => (
                            <tr {...footerGroup.getFooterGroupProps()} >
                                {footerGroup.headers.map(column => (
                                    <th>
                                        <div className='flex items-center justify-center'>
                                            {column.render('Footer')}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                </table>
            </div>
        </div>
    );
};