import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const targetingApi = createApi({
    reducerPath: 'targeting',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchTargetings: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/targetings',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
            fetchTargetingsSb: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/targetingssb',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
            fetchTargetingsSd: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/targetingssd',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),
            fetchTargetingsList: builder.query({
                query: ({ id, accountId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/targetinglistsp',
                        method: 'GET',
                        params: {
                            id,
                            accountId
                        }
                    }
                }
            }),
            fetchTargetingsListSb: builder.query({
                query: ({ id, accountId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/targetinglistsb',
                        method: 'GET',
                        params: {
                            id,
                            accountId
                        }
                    }
                }
            }),
            fetchTargetingsListSd: builder.query({
                query: ({ id, accountId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/targetinglistsd',
                        method: 'GET',
                        params: {
                            id,
                            accountId
                        }
                    }
                }
            }),
            getPataraBid: builder.query({
                query: ({ row, targetAcos, avgPrice }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/optimization/targeting',
                        method: 'GET',
                        params: {
                            keywordBid: row.keywordBid,
                            cost_1: row.cost_1,
                            cost_4: row.cost_4,
                            cost_8: row.cost_8,
                            cost_13: row.cost_13,
                            sales_1: row.sales_1,
                            sales_4: row.sales_4,
                            sales_8: row.sales_8,
                            sales_13: row.sales_13,
                            impressions_1: row.impressions_1,
                            impressions_4: row.impressions_4,
                            impressions_8: row.impressions_8,
                            impressions_13: row.impressions_13,
                            clicks_1: row.clicks_1,
                            clicks_4: row.clicks_4,
                            clicks_8: row.clicks_8,
                            clicks_13: row.clicks_13,
                            orders_1: row.orders_1,
                            orders_4: row.orders_4,
                            orders_8: row.orders_8,
                            orders_13: row.orders_13,
                            keyword: row.targeting,
                            targetAcos,
                            avgPrice,
                            bid: row.bid
                        }
                    }
                }
            }),
            updateBid: builder.mutation({
                query: ({ selectedKeywords, accountId, campaignId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    console.log('targetingApi@ keywords array: ', selectedKeywords)
                    console.log('targetingApi@ account id: ', accountId)
                    console.log('targetingApi@ campaign id: ', campaignId)
                    return {
                        url: '/api/targetings/updatebid',
                        method: 'POST',
                        body: {
                            keywords: selectedKeywords,
                            accountId,
                            campaignId
                        }
                    }
                }
            }),
            updateBidSb: builder.mutation({
                query: ({ selectedKeywords, accountId, campaignId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    console.log('targetingApi@ keywords array: ', selectedKeywords)
                    console.log('targetingApi@ account id: ', accountId)
                    console.log('targetingApi@ campaign id: ', campaignId)
                    return {
                        url: '/api/targetings/updatebidsb',
                        method: 'POST',
                        body: {
                            keywords: selectedKeywords,
                            accountId,
                            campaignId
                        }
                    }
                }
            }),
            updateBidSd: builder.mutation({
                query: ({ selectedKeywords, accountId, campaignId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    console.log('targetingApi@ keywords array: ', selectedKeywords)
                    console.log('targetingApi@ account id: ', accountId)
                    console.log('targetingApi@ campaign id: ', campaignId)
                    return {
                        url: '/api/targetings/updatebidsd',
                        method: 'POST',
                        body: {
                            keywords: selectedKeywords,
                            accountId,
                            campaignId
                        }
                    }
                }
            }),
        }
    }
});

export { targetingApi };
export const { useFetchTargetingsQuery, useFetchTargetingsSbQuery, useFetchTargetingsSdQuery, useFetchTargetingsListQuery, useFetchTargetingsListSbQuery, useFetchTargetingsListSdQuery, useUpdateBidMutation, useUpdateBidSbMutation, useUpdateBidSdMutation, useGetPataraBidQuery } = targetingApi;