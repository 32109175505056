import { useState } from "react";
import { useFetchUserQuery } from "../../store";
import { useSelector } from "react-redux";

import SelectAccountDiv from "../SelectAccountDiv"
import CampaignsDropdown from "../CampaignsDropdownSd";
import TargetingList from "./TargetingListSd";

function OptimizationPageSd() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [campaignId, setCampaignId] = useState(null);
    const { data } = useFetchUserQuery();

    let content = <div></div>;
    if (data) {
        content = (<div>
            {accountId
                ? <div>
                    <div className="flex px-5 py-1">
                        <CampaignsDropdown setCampaignId={setCampaignId} />
                    </div>
                    {campaignId
                        ? <TargetingList campaignId={campaignId} accountId={accountId} />
                        : <div className="m-5 p-2 rounded-xl shadow">Select a campaign to display targeting performance</div>
                    }
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }
        </div>
        )
    }
    return (
        <div className="flex flex-col h-screen">
            {content}
        </div>
    );
}
export default OptimizationPageSd;