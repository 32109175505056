import { useSelector } from "react-redux";
import { useFetchProfilesQuery } from "../../store";
import SelectAccountDiv from "../SelectAccountDiv"

function ReportsPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const { data, error, isFetching } = useFetchProfilesQuery();

    let profileName;
    //let targetACOS, avgPrice;
    if (isFetching) {
        profileName = "Loading...";
    } else if (error) {
        profileName = "Error!";
    } else if (data && accountId) {
        const currentProfile = data.find(profile => {
            return profile.accountId === accountId;
        });
        profileName = currentProfile.accountName;
        //targetACOS = currentProfile.targetACOS;
        //avgPrice = currentProfile.avgPrice;
    }

    return (
        <div>
            {accountId
                ? <div className="flex flex-col  m-2">
                    <div className='px-5 py-2 ' >
                        Reports
                    </div>
                    <div className='px-5 py-1 ' >
                        {accountId}
                    </div>
                    <div className="flex flex-row justify-center  px-5 py-2 ">
                        <div className='px-6 py-3 rounded-lg text-white text-lg bg-yellow-500  w-52' >
                            {profileName}
                        </div>
                    </div>
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }

        </div>
    );
}

export default ReportsPage;