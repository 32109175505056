import PataraBid from "./PataraBidSb";
import AcosDisplay from "./AcosDisplayDiv";

export const GROUPED_COLUMNS = [
    {
        Header: '',
        Footer: '',
        id: 'patara',
        columns: [
            {
                Header: 'Keyword',
                Footer: 'Keyword',
                accessor: 'targetingText',
                Cell: ({ row, value }) => {
                    let text = value;
                    let category, brand, rating, prime;
                    const lastUpdate = row.original.lastUpdate
                    let updatedDate = "Never", updatedTime = "", updatedFrom = "", updatedTo = "", updatedBy = ""
                    if (lastUpdate) {
                        const date = new Date(lastUpdate.updatedDate)
                        updatedDate = date.toISOString().split('T')[0]
                        updatedTime = date.toISOString().slice(11, 16)
                        updatedFrom = lastUpdate.updatedFrom
                        updatedTo = lastUpdate.updatedTo
                        updatedBy = lastUpdate.updatedBy
                    }
                    if (value.includes('category="')) {
                        const index = value.indexOf('"', 10);
                        text = value.slice(10, index);
                        category = value.slice(10, index);
                        if (value.includes('brand="')) {
                            const indexBrStart = value.indexOf('brand="') + 7;
                            const indexBrEnd = value.indexOf('"', indexBrStart);
                            brand = value.slice(indexBrStart, indexBrEnd);
                        }
                        if (value.includes('rating=')) {
                            const indexRating = value.indexOf('rating=') + 7;
                            rating = value.slice(indexRating);
                        }
                        if (value.includes('prime-shipping-eligible="')) {
                            const indexPrime = value.indexOf('prime-shipping-eligible="') + 25;
                            prime = value.slice(indexPrime, indexPrime + 5);
                        }
                    }

                    return (
                        <div className="pl-1 break-words">
                            {value.includes('asin="')
                                ? <div>
                                    <a href={"https://www.amazon.com/dp/" + value.slice(6, 16)} rel='noreferrer' target="_blank">
                                        <div className='flex hover:text-sky-600 hover:cursor-pointer'>{value.slice(6, 16)}</div>
                                    </a>
                                    <div className="text-xxs text-gray-400">{row.original.keywordId || row.original.targetId}</div>
                                    <div className="text-xxs text-gray-400">{row.original.targetingType}</div>
                                </div>
                                :
                                value.includes('category="')
                                    ?
                                    <div>
                                        <a href={"https://www.amazon.com/s?k=" + category} rel='noreferrer' target="_blank">
                                            <div className='flex hover:text-sky-600 hover:cursor-pointer'>{text}</div>
                                        </a>
                                        <div className="flex flex-row text-xxs mt-1">
                                            {brand && <div className="mr-3">Brand: {brand}</div>}
                                            {rating && <div>Rating: {rating}</div>}
                                            {prime && <div>Prime: {prime}</div>}
                                        </div>
                                        <div className="text-xxs text-gray-400">{row.original.keywordId || row.original.targetId}</div>
                                        <div className="text-xxs text-gray-400">{row.original.targetingType}</div>
                                    </div>
                                    :
                                    row.original.targetingType === 'EXACT' || row.original.targetingType === 'PHRASE' || row.original.targetingType === 'BROAD'
                                        ? <div>
                                            <div className='flex items-center'>
                                                <a href={"https://www.amazon.com/s?k=" + value} rel='noreferrer' target="_blank" className='flex hover:text-sky-600'>
                                                    {value}
                                                </a>
                                                <div className="bg-sky-500 text-white rounded-full w-4 h-4 flex justify-center text-xxs ml-2 lowercase font-bold">
                                                    {row.original.targetingType.slice(0, 1)}
                                                </div>
                                            </div>
                                            <div className="text-xxs text-gray-400">{row.original.keywordId || row.original.targetId}</div>
                                            <div className="text-xxs text-gray-400">{row.original.targetingType}</div>
                                        </div>
                                        : <div>
                                            <div className='flex'>{value}</div>
                                            <div className="text-xxs text-gray-400">{row.original.keywordId || row.original.targetId}</div>
                                            <div className="text-xxs text-gray-400">{row.original.targetingType}</div>
                                        </div>
                            }
                            {
                                lastUpdate
                                    ? <div className=" flex flex-col text-xxs text-gray-700 bg-gray-200 rounded-lg px-2 py-1">
                                        <div>{updatedDate}</div>
                                        <div>{updatedTime}</div>
                                        <div className="font-bold">{updatedFrom + " >>> " + updatedTo}</div>
                                        <div>{updatedBy}</div>
                                        <div></div></div>
                                    : <div className="text-xxs text-gray-700 bg-gray-200 rounded-lg px-2 py-1">Last Update: Never</div>
                            }
                        </div>
                    )
                }
            },
            {
                Header: 'Patara Bid',
                Footer: 'Patara Bid',
                id: 'pataraBid',
                Cell: ({ row }) => <PataraBid id={row.original.targetId} row={row.original} currBid={row.original.bid}></PataraBid>
            },
        ]
    },
    {
        Header: '7 Days',
        Footer: '7 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_1',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_1
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '30 Days',
        Footer: '30 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_4',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_4
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_4',
                id: "spend_4",
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '60 Days',
        Footer: '60 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_8',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_8
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '90 Days',
        Footer: '90 Days',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_13',
                sortDescFirst: true,
                Cell: ({ value, row }) => {
                    const spend = row.original.cost_13
                    return <AcosDisplay value={value} spend={spend}></AcosDisplay>
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
]