//import AccountDropdown from "./AccountDropdown";
import patara from './photos/patara.png';
import Link from "./Link";
import { MdEmail, MdOutlinePhoneInTalk, MdLocationPin } from "react-icons/md";
//import { updateAccount, useFetchUserQuery } from "../store";
//import { useDispatch } from "react-redux";

function Header2() {
    //const dispatch = useDispatch();
    //const { data, error, isFetching } = useFetchUserQuery();

    return (
        <div className='sticky inset-x-0 bottom-0'>
            <div className="h-[14vh] flex flex-row justify-evenly px-5 py-5 bg-sky-50 text-xs" >
                <div className="w-28 h-14" >
                    <img src={patara} alt="patara logo" />
                </div>
                <div className='ml-12' >
                    <h1 className='font-bold mb-2' >Patara Bilişim Ltd. Şti.</h1>
                    <div className='flex items-center' >
                        <MdOutlinePhoneInTalk className='text-lg mr-2'></MdOutlinePhoneInTalk>
                        <h3>+90 534 051 46 51</h3>
                    </div>
                    <div className='flex items-center' >
                        <MdEmail className='text-lg mr-2'></MdEmail>
                        <h3>admin@consultpatara.com</h3>
                    </div>
                    <div className='flex items-center' >
                        <MdLocationPin className='text-lg mr-2'></MdLocationPin>
                        <h3>Ankara, TÜRKİYE</h3>
                    </div>
                </div>
            </div>
            <div className='h-[4vh] flex flex-row justify-center items-center px-4 py-3 bg-gray-200 text-xxs'>
                <div>Copyright © 2023 Patara Bilişim Ltd. Şti. All rights reserved.</div>
                <Link key={'/privacypolicy'} to={'/privacypolicy'}
                    className='ml-4'
                    activeClassName='ml-4' >{'Privacy Policy'}
                </Link>
            </div>
        </div>
    );
}

export default Header2;