import { createSlice } from '@reduxjs/toolkit';

const pataraBidsSlice = createSlice({
    name: 'patarabids',
    initialState: {
        pataraBids: [],
    },
    reducers: {
        updatePataraBids(state, action) {
            action.payload.forEach(keyword => {
                const index = state.pataraBids.findIndex(targeting => targeting.id === keyword.id)
                if (index > -1) {
                    //console.log('updated existing keyword, bid: ', keyword.bid)
                    state.pataraBids[index].bid = keyword.bid;
                    if (keyword.isSubmitted) {
                        state.pataraBids[index].isSubmitted = keyword.isSubmitted;
                    }
                } else {
                    //console.log('added new keyword, bid: ', keyword.bid)
                    state.pataraBids.push(keyword);
                }
            });
        },
    },
});

export const pataraBidsReducer = pataraBidsSlice.reducer;
export const { updatePataraBids } = pataraBidsSlice.actions;