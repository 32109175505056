//import { useState } from "react";
import Dropdown from "./Dropdown";
import { useSelector } from "react-redux";
import { useFetchProfilesQuery } from "../store";

function AccountDropdown({ setAccountId, updateCampaign, updatePortfolio }) {
    const accountName = useSelector(state => state.accounts.accountName);
    //const [selectedItem, setSelectedItem] = useState(accountName);
    const { data, error, isFetching } = useFetchProfilesQuery();

    const onChange = (item, id) => {
        const currentAccount = data.find(profile => {
            return profile.accountId === id;
        });
        setAccountId({ id, accountName: item, acos: currentAccount.targetACOS, avgPrice: currentAccount.avgPrice, maxBid: currentAccount.maxBid || 0 });
        updateCampaign({ campaignId: null, campaignName: null })
        updatePortfolio({ portfolioId: null, portfolioName: null })
    };

    let contentDropdown;
    if (isFetching) {
        contentDropdown = <Dropdown options={[]}
            //onChange={(newItem) => setSelectedItem(newItem)}
            value={accountName} defaultText="Loading..."
            className='w-52' />
    } else if (error) {
        console.log(error);
        contentDropdown = <Dropdown options={[]}
            //onChange={(newItem) => setSelectedItem(newItem)}
            value={null} defaultText="Error!"
            className='w-52' />
    } else if (data) {
        const profileOptions = data.map(profile => {
            return {
                label: profile.accountName,
                value: profile.accountId
            }
        });

        let sortedProfileOptions = [...profileOptions].sort((a, b) => {
            const valueA = a.label;
            const valueB = b.label;
            return valueA.localeCompare(valueB);
        });

        contentDropdown = <Dropdown options={sortedProfileOptions}
            onChange={onChange}
            value={accountName} defaultText="Select Account"
            className='w-52' />
    }

    return (
        <div>
            {contentDropdown}
        </div>
    );
}

export default AccountDropdown;