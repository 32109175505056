import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const reportingApi = createApi({
    reducerPath: 'reporting',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            requestReports: builder.mutation({
                query: () => {
                    return {
                        url: '/api/amazon/requestWeeklyTargetingReport',
                        method: 'POST',
                        body: {

                        }
                    }
                }
            }),
            requestReports12: builder.mutation({
                query: () => {
                    return {
                        url: '/api/amazon/request12WeeklyTargetingReport',
                        method: 'POST',
                        body: {

                        }
                    }
                }
            }),
        }
    }
});

export { reportingApi };
export const { useRequestReportsMutation, useRequestReports12Mutation } = reportingApi;