import { useState, useEffect, useRef } from "react";
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import Panel from "./Panel";
import classNames from "classnames";
import { BsCalendarMonth } from "react-icons/bs";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

function DateRangePickerCustom({ defaultText, hidden, setPickedDate, pickedDate, setButtonColor, setSelectedDate }) {
    const [showMenu, setShowMenu] = useState(false);

    //kaç gün seçildiğini hesaplamak için / days
    const dateNow = new Date();
    let startIndex = Math.floor(((dateNow.getTime() - (2 * 1000 * 60 * 60 * 24)) - pickedDate[0].endDate.getTime()) / (1000 * 60 * 60 * 24));
    let endIndex = Math.floor(((dateNow.getTime() - (2 * 1000 * 60 * 60 * 24)) - pickedDate[0].startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    let days = endIndex - startIndex;

    let dateStartPreview = <div className="flex flex-row justify-between items-center">
        <BsCalendarMonth className="text-xl mr-4"></BsCalendarMonth>
        <div className="rounded-lg p-1 px-3 mx-1 shadow">{pickedDate[0].startDate.getFullYear() + "-" + (pickedDate[0].startDate.getMonth() + 1) + "-" + pickedDate[0].startDate.getDate()}</div>
        <div className="rounded-lg p-1 px-3 mx-1 shadow">{pickedDate[0].endDate.getFullYear() + "-" + (pickedDate[0].endDate.getMonth() + 1) + "-" + pickedDate[0].endDate.getDate()}</div>
        <div className="rounded-lg p-1 px-3 ml-3 shadow text-gray-500 text-xxs">{days + " Days"}</div>
    </div>

    const divEl = useRef();
    const finalClassName = classNames('relative z-20 w-96');
    const panelClassName = classNames('flex justify-between items-center cursor-pointer py-2 text-xs h-12');
    const optionsClassName = classNames('absolute top-full overflow-scroll mt-2');

    useEffect(() => {
        const handler = (event) => {
            //if div is not visible, return earlier
            if (!divEl.current) {
                return;
            }

            //if our ref div doesnt contain click event target (so it is outside of our div)
            if (!divEl.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('click', handler, true);

        return () => {
            document.removeEventListener('click', handler);
        };

    }, []);

    const onSelectClick = () => {
        setShowMenu(!showMenu);
    };

    //takvimde seçilebilecek en erken ve en son tarihleri belirlemek için
    let maxDate = new Date();
    maxDate.setDate(new Date().getDate() - 2);
    maxDate = new Date(maxDate.getTime() - (6 * 60 * 60 * 1000));
    let minDate = new Date();
    minDate.setDate(new Date().getDate() - 91);
    minDate = new Date(minDate.getTime() - (6 * 60 * 60 * 1000));

    return (
        <div ref={divEl} className={finalClassName} hidden={hidden}>
            <Panel className={panelClassName}
                onClick={onSelectClick} >
                {dateStartPreview || defaultText}
                {!showMenu ? <GoChevronDown className="text-xl" /> : <GoChevronUp className="text-xl" />}
            </Panel>
            {showMenu && <Panel className={optionsClassName} >
                <div >
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => {
                            setPickedDate([item.selection]);
                            setButtonColor(0)
                            setSelectedDate(item.selection)
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={pickedDate}
                        maxDate={maxDate}
                        minDate={minDate}
                        scroll={{ enabled: true }}
                        showDateDisplay={false}
                    />
                </div>
            </Panel>
            }
        </div>);
}

export default DateRangePickerCustom;