//import { useSelector } from "react-redux";
import { useState } from "react";

//import { useFetchProfilesQuery } from "../../store";
import DateRangePickerCustom from "../DateRangePickerCustom";
import OverviewList from "./OverviewListSb";


function OverviewPage() {

    const [buttonColor, setButtonColor] = useState(0);

    const dateNow = new Date();
    let start = new Date();
    let end = new Date();
    //const offset = new Date().getTimezoneOffset()
    start.setDate(dateNow.getDate() - (2));
    end.setDate(dateNow.getDate() - (31));
    start = new Date(start.getTime() - (6 * 60 * 60 * 1000))
    end = new Date(end.getTime() - (6 * 60 * 60 * 1000))
    const [pickedDate, setPickedDate] = useState([
        {
            startDate: end,
            endDate: start,
            key: 'selection'
        }
    ]);

    const onClick = (days) => {
        let start = new Date();
        let end = new Date();
        //const offset = new Date().getTimezoneOffset()
        start.setDate(dateNow.getDate() - (2));
        end.setDate(dateNow.getDate() - (days + 1));
        start = new Date(start.getTime() - (6 * 60 * 60 * 1000))
        end = new Date(end.getTime() - (6 * 60 * 60 * 1000))
        //console.log(days);
        setButtonColor(days)
        setPickedDate([{ startDate: end, endDate: start, key: "selection" }])
    }

    return (
        <div className="flex flex-col  m-2">
            <div className="flex px-5 py-1">
                <DateRangePickerCustom setPickedDate={setPickedDate} pickedDate={pickedDate} setButtonColor={setButtonColor} defaultText="Pick a Date"></DateRangePickerCustom>
                <div className="text-xs">
                    {buttonColor === 7
                        ? <button onClick={() => onClick(7)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">7 Days</button>
                        : <button onClick={() => onClick(7)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">7 Days</button>
                    }

                    {buttonColor === 15
                        ? <button onClick={() => onClick(15)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">15 Days</button>
                        : <button onClick={() => onClick(15)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">15 Days</button>
                    }

                    {buttonColor === 30
                        ? <button onClick={() => onClick(30)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">30 Days</button>
                        : <button onClick={() => onClick(30)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">30 Days</button>
                    }

                    {buttonColor === 60
                        ? <button onClick={() => onClick(60)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">60 Days</button>
                        : <button onClick={() => onClick(60)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">60 Days</button>
                    }

                    {buttonColor === 90
                        ? <button onClick={() => onClick(90)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20 bg-sky-100">90 Days</button>
                        : <button onClick={() => onClick(90)} className="rounded-lg shadow ml-4 px-2 border h-12 w-20">90 Days</button>
                    }
                </div>
            </div>
            <div className="flex flex-row justify-center  px-5 py-2 ">
            </div>
            <OverviewList pickedDate={pickedDate[0]} ></OverviewList>
        </div>
    );
}

export default OverviewPage;