import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const searchTermApi = createApi({
    reducerPath: 'searchterm',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchSearchTerms: builder.query({
                query: (id) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/searchterms',
                        method: 'GET',
                        params: {
                            id
                        }
                    }
                }
            }),

            //DELETE THE FOLLOWING QUERYS
            getPataraBid: builder.query({
                query: ({ row, targetAcos, avgPrice }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/optimization/targeting',
                        method: 'GET',
                        params: {
                            keywordBid: row.keywordBid,
                            cost_4: row.cost_4,
                            cost_8: row.cost_8,
                            cost_13: row.cost_13,
                            sales_4: row.sales_4,
                            sales_8: row.sales_8,
                            sales_13: row.sales_13,
                            impressions_4: row.impressions_4,
                            impressions_8: row.impressions_8,
                            impressions_13: row.impressions_13,
                            clicks_4: row.clicks_4,
                            clicks_8: row.clicks_8,
                            clicks_13: row.clicks_13,
                            orders_4: row.orders_4,
                            orders_8: row.orders_8,
                            orders_13: row.orders_13,
                            keyword: row.targeting,
                            targetAcos,
                            avgPrice,
                        }
                    }
                }
            }),
            updateBid: builder.mutation({
                query: ({ selectedKeywords, accountId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    console.log('targetingApi@ keywords array: ', selectedKeywords)
                    console.log('targetingApi@ account id: ', accountId)
                    return {
                        url: '/api/targetings/updatebid',
                        method: 'POST',
                        body: {
                            keywords: selectedKeywords,
                            accountId
                        }
                    }
                }
            }),
        }
    }
});

export { searchTermApi };
export const { useFetchSearchTermsQuery, useUpdateBidMutation, useGetPataraBidQuery } = searchTermApi;