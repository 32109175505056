import { useFetchSearchTermsQuery } from "../../store";
import SearchTermTable from './SearchTermTable';
import Skeleton from '../Skeleton';
import Panel from "../Panel";

function SearchTermsList({ campaignId }) {
    const { data, error, isFetching } = useFetchSearchTermsQuery(campaignId);

    let columns = [{ label: '4 Weeks', content: null }, { label: '8 Weeks', content: null }, { label: '13 Weeks', content: null }];
    let tableContent, performanceContent, finalContent;
    if (isFetching) {
        finalContent = columns.map((column, index) => {
            return (
                <Skeleton times={1} key={index} className="h-12 w-96 mx-4" />
            );
        })
        tableContent = <Skeleton times={5} className="h-12 w-full" />;
    } else if (error) {
        tableContent = <div>Error!!! Please contact Patara</div>
    } else if (data) {
        //13 adet hafta başlangıç ve bitiş tarihleri
        const dateNow = new Date();
        const daysSinceLastWeek = dateNow.getDay();
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 52; i++) {
            let start = new Date();
            let end = new Date();
            const offset = new Date().getTimezoneOffset()
            start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * i));
            end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * i));
            start = new Date(start.getTime() - (offset * 60 * 1000))
            end = new Date(end.getTime() - (offset * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Week ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }

        //PAUSED OLAN TARGETINGLERI DATA ARRAYİ İÇİNDEN ÇIKART. AŞAĞIDA YENİ ARRAY İLE İŞLEM YAP
        const dataEnabled = data.filter(targeting => {
            return targeting.adKeywordStatus === "ENABLED";
        });


        if (dataEnabled.length > 0) {
            //console.log(data);
            const tableData = dataEnabled.map(targeting => {
                let updatedTargeting = {
                    ...targeting,
                    cost_1: 0,
                    sales_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                }

                targeting.performance.forEach(performance => {

                    for (let i = 0; i < 1; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_1: updatedTargeting.cost_1 + performance.cost,
                                sales_1: updatedTargeting.sales_1 + performance.sales14d,
                                impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                orders_1: updatedTargeting.orders_1 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_4: updatedTargeting.cost_1,
                    sales_4: updatedTargeting.sales_1,
                    impressions_4: updatedTargeting.impressions_1,
                    clicks_4: updatedTargeting.clicks_1,
                    orders_4: updatedTargeting.orders_1,
                }

                targeting.performance.forEach(performance => {

                    for (let i = 1; i < 4; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_4: updatedTargeting.cost_4 + performance.cost,
                                sales_4: updatedTargeting.sales_4 + performance.sales14d,
                                impressions_4: updatedTargeting.impressions_4 + performance.impressions,
                                clicks_4: updatedTargeting.clicks_4 + performance.clicks,
                                orders_4: updatedTargeting.orders_4 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_8: updatedTargeting.cost_4,
                    sales_8: updatedTargeting.sales_4,
                    impressions_8: updatedTargeting.impressions_4,
                    clicks_8: updatedTargeting.clicks_4,
                    orders_8: updatedTargeting.orders_4,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 4; i < 8; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_8: updatedTargeting.cost_8 + performance.cost,
                                sales_8: updatedTargeting.sales_8 + performance.sales14d,
                                impressions_8: updatedTargeting.impressions_8 + performance.impressions,
                                clicks_8: updatedTargeting.clicks_8 + performance.clicks,
                                orders_8: updatedTargeting.orders_8 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_13: updatedTargeting.cost_8,
                    sales_13: updatedTargeting.sales_8,
                    impressions_13: updatedTargeting.impressions_8,
                    clicks_13: updatedTargeting.clicks_8,
                    orders_13: updatedTargeting.orders_8,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 8; i < 13; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_13: updatedTargeting.cost_13 + performance.cost,
                                sales_13: updatedTargeting.sales_13 + performance.sales14d,
                                impressions_13: updatedTargeting.impressions_13 + performance.impressions,
                                clicks_13: updatedTargeting.clicks_13 + performance.clicks,
                                orders_13: updatedTargeting.orders_13 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_26: updatedTargeting.cost_13,
                    sales_26: updatedTargeting.sales_13,
                    impressions_26: updatedTargeting.impressions_13,
                    clicks_26: updatedTargeting.clicks_13,
                    orders_26: updatedTargeting.orders_13,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 13; i < 26; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_26: updatedTargeting.cost_26 + performance.cost,
                                sales_26: updatedTargeting.sales_26 + performance.sales14d,
                                impressions_26: updatedTargeting.impressions_26 + performance.impressions,
                                clicks_26: updatedTargeting.clicks_26 + performance.clicks,
                                orders_26: updatedTargeting.orders_26 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_52: updatedTargeting.cost_26,
                    sales_52: updatedTargeting.sales_26,
                    impressions_52: updatedTargeting.impressions_26,
                    clicks_52: updatedTargeting.clicks_26,
                    orders_52: updatedTargeting.orders_26,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 26; i < 52; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_52: updatedTargeting.cost_52 + performance.cost,
                                sales_52: updatedTargeting.sales_52 + performance.sales14d,
                                impressions_52: updatedTargeting.impressions_52 + performance.impressions,
                                clicks_52: updatedTargeting.clicks_52 + performance.clicks,
                                orders_52: updatedTargeting.orders_52 + performance.purchases14d,
                            }
                        }
                    }
                });

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.cost_1 / updatedTargeting.clicks_1).toFixed(2),
                    cpc_4: (updatedTargeting.cost_4 / updatedTargeting.clicks_4).toFixed(2),
                    cpc_8: (updatedTargeting.cost_8 / updatedTargeting.clicks_8).toFixed(2),
                    cpc_13: (updatedTargeting.cost_13 / updatedTargeting.clicks_13).toFixed(2),
                    cpc_26: (updatedTargeting.cost_26 / updatedTargeting.clicks_26).toFixed(2),
                    cpc_52: (updatedTargeting.cost_52 / updatedTargeting.clicks_52).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.cost_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    acos_4: updatedTargeting.sales_4 === 0 ? 0 : ((updatedTargeting.cost_4 / updatedTargeting.sales_4) * 100).toFixed(2),
                    acos_8: updatedTargeting.sales_8 === 0 ? 0 : ((updatedTargeting.cost_8 / updatedTargeting.sales_8) * 100).toFixed(2),
                    acos_13: updatedTargeting.sales_13 === 0 ? 0 : ((updatedTargeting.cost_13 / updatedTargeting.sales_13) * 100).toFixed(2),
                    acos_26: updatedTargeting.sales_26 === 0 ? 0 : ((updatedTargeting.cost_26 / updatedTargeting.sales_26) * 100).toFixed(2),
                    acos_52: updatedTargeting.sales_52 === 0 ? 0 : ((updatedTargeting.cost_52 / updatedTargeting.sales_52) * 100).toFixed(2),
                };
            })

            let spend1 = 0, sales1 = 0, spend2 = 0, sales2 = 0, spend3 = 0, sales3 = 0;

            tableData.forEach(item => {
                spend1 = spend1 + item.cost_4;
                sales1 = sales1 + item.sales_4;
                spend2 = spend2 + item.cost_8;
                sales2 = sales2 + item.sales_8;
                spend3 = spend3 + item.cost_13;
                sales3 = sales3 + item.sales_13;
            });

            //PAUSED OLAN TARGETINGLERI DATA ARRAYİ İÇİNDEN ÇIKART. AŞAĞIDA YENİ ARRAY İLE İŞLEM YAP
            const tableDataFinal = tableData.filter(targeting => {
                return targeting.cost_13 > 5;
            });

            //TABLE CONTENT OLUŞTURULMADAN ÖNCE ORT FİYAT HESAPLAMASI YAPILACAK.
            //TOPLAM SATIŞ / SİPARİŞ ADEDİ
            tableContent = (
                <SearchTermTable data={tableDataFinal} />
                //<StickyTable data={tableData} />
            );

            const summary1 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: {spend1.toFixed(2)}</div>
                <div className="mx-3">Sales: {sales1.toFixed(2)}</div>
                <div className="mx-3">ACOS: {(spend1 / sales1 * 100).toFixed(2)}</div>
            </div>
            const summary2 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: {spend2.toFixed(2)}</div>
                <div className="mx-3">Sales: {sales2.toFixed(2)}</div>
                <div className="mx-3">ACOS: {(spend2 / sales2 * 100).toFixed(2)}</div>
            </div>
            const summary3 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: {spend3.toFixed(2)}</div>
                <div className="mx-3">Sales: {sales3.toFixed(2)}</div>
                <div className="mx-3">ACOS: {(spend3 / sales3 * 100).toFixed(2)}</div>
            </div>

            columns = [
                { label: '4 Weeks', content: summary1 },
                { label: '8 Weeks', content: summary2 },
                { label: '13 Weeks', content: summary3 }
            ];

            performanceContent = columns.map((column, index) => {
                return (
                    <div key={index} className="px-3 py-1 mx-4 shadow rounded-lg text-white bg-sky-800">
                        <div className="mb-2 pb-2 font-bold border-b-2 justify-center flex">{column.label}</div>
                        {column.content}
                    </div>
                );
            })

            finalContent = <Panel >
                <div className="flex flex-row overflow-scroll justify-between">
                    {performanceContent}
                </div>
            </Panel >
        }
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-row px-5 py-1">
                {finalContent}
            </div>
            <div className="mx-5 my-1 text-sm">
                {tableContent}
            </div>
        </div >
    );
}

export default SearchTermsList;