import { useFetchCampaignsReportQuery, useFetchSalesReportQuery, useFetchSbCampaignsReportQuery, useFetchSdCampaignsReportQuery } from "../../store";
import Skeleton from '../Skeleton';
import PerformanceTable from "./PerformanceTableMonthly"

export default function PerformanceList({ accountId }) {
    const { data, isFetching } = useFetchCampaignsReportQuery(accountId);
    const { data: dataSb, isFetching: isFetchingSb } = useFetchSbCampaignsReportQuery(accountId);
    const { data: dataSd, isFetching: isFetchingSd } = useFetchSdCampaignsReportQuery(accountId);
    const { data: dataSales, isFetching: isFetchingSales } = useFetchSalesReportQuery(accountId);

    let tableContent

    const monthsToDisplay = 12
    const dateNow = new Date();
    const monthNow = dateNow.getMonth()
    const dayNow = dateNow.getDate()
    const yearNow = dateNow.getFullYear()
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let lastDayofMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if ((yearNow % 4 === 0) || ((yearNow % 4 === 1) && (monthNow === 1))) {
        lastDayofMonth[1] = 29
    }
    let monthLength = []

    console.log("Day: ", dayNow, " ,Month: ", monthNow, " ", months[monthNow], " ,Year: ", yearNow)
    let dateArray = [], dateStrArray = [], dateStartArray = [], dateEndArray = [];
    for (let i = 0; i < monthsToDisplay; i++) {
        let start = new Date();
        let end = new Date();
        const month = ((monthNow - i) % 12 + 12) % 12
        if (i > monthNow) {
            start.setFullYear(yearNow - 1)
            end.setFullYear(yearNow - 1)
        }
        start.setDate(1);
        end.setDate(1);
        start.setMonth(month)
        end.setMonth(month)
        if (i === 0) {
            console.log("end date: ", dayNow)
            if (dayNow > 2)
                end.setDate(dayNow - 2);
        }
        else {
            end.setDate(lastDayofMonth[month]);
        }
        start = new Date(start.getTime())
        end = new Date(end.getTime())
        monthLength[i] = end.getDate()
        dateStartArray[i] = start.toISOString().split('T')[0];
        dateEndArray[i] = end.toISOString().split('T')[0];
        dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
        console.log(dateArray[i], ", Start: ", start, ", End: ", end)
        dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " "
            + start.getDate()
            + " - "
            + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
    }

    let week = []
    tableContent = <div className="shadow border p-2 m-3 rounded-xl">Data cannot be fetched at the moment. Contact admin for assistance.</div>
    if (isFetching || isFetchingSales || isFetchingSb || isFetchingSd) {
        tableContent = <div className="shadow p-2 m-3 rounded-xl"><Skeleton times={10} className="h-10 w-full" /></div>;
    } else if (data && dataSales && dataSb && dataSd) {

        for (let j = 0; j < monthsToDisplay; j++) {
            let dayArray = [], dayStartArray = [], dayEndArray = [];
            for (let i = 0; i < lastDayofMonth[((monthNow - j) % 12 + 12) % 12]; i++) {
                let start = new Date();
                let end = new Date();
                if (j > monthNow) {
                    start.setFullYear(yearNow - 1)
                    end.setFullYear(yearNow - 1)
                }
                start.setMonth(((monthNow - j) % 12 + 12) % 12)
                end.setMonth(((monthNow - j) % 12 + 12) % 12)
                start.setDate(i + 1);
                end.setDate(i + 1);
                start = new Date(start.getTime())
                end = new Date(end.getTime())
                dayStartArray[i] = start.toISOString().split('T')[0];
                dayEndArray[i] = end.toISOString().split('T')[0];
                dayArray[i] = dayStartArray[i] + "-" + dayEndArray[i];
                //console.log('Day ', i, ': Start Date: ', dayStartArray[i], ', End Date: ', dayEndArray[i]);
            }

            //buradaki newData array olacak. her hafta için yeni bir obje açılacak.
            const newData = data.map(campaign => {
                let updatedCampaign = {
                    ...campaign,
                    sales: 0,
                    spend: 0
                }
                for (let i = 0; i < lastDayofMonth[((monthNow - j) % 12 + 12) % 12]; i++) {
                    //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                    const day = campaign.performance.find(day => day.dateRange === dayArray[i])
                    //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                    if (day) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            sales: updatedCampaign.sales + day.sales7d,
                            spend: updatedCampaign.spend + day.spend
                        }
                    }
                }
                return updatedCampaign
            })

            const newDataSb = dataSb.map(campaign => {
                let updatedCampaign = {
                    ...campaign,
                    sales: 0,
                    spend: 0
                }
                for (let i = 0; i < lastDayofMonth[((monthNow - j) % 12 + 12) % 12]; i++) {
                    //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                    const day = campaign.performance.find(day => day.dateRange === dayArray[i])
                    //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                    if (day) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            sales: updatedCampaign.sales + day.attributedSales14d,
                            spend: updatedCampaign.spend + day.cost
                        }
                    }
                }
                return updatedCampaign
            })

            const newDataSd = dataSd.map(campaign => {
                let updatedCampaign = {
                    ...campaign,
                    sales: 0,
                    spend: 0
                }
                for (let i = 0; i < lastDayofMonth[((monthNow - j) % 12 + 12) % 12]; i++) {
                    //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                    const day = campaign.performance.find(day => day.dateRange === dayArray[i])
                    //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                    if (day) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            sales: updatedCampaign.sales + day.attributedSales14d,
                            spend: updatedCampaign.spend + day.cost
                        }
                    }
                }
                return updatedCampaign
            })


            let updatedDataSales = {
                ...dataSales,
                sales: 0,
                orders: 0,
                units: 0
            }
            for (let i = 0; i < lastDayofMonth[((monthNow - j) % 12 + 12) % 12]; i++) {
                //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                const day = dataSales.performance.find(day => day.dateRange === dayArray[i])
                //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                if (day) {
                    updatedDataSales = {
                        ...dataSales,
                        sales: updatedDataSales.sales + day.totalSales,
                        orders: updatedDataSales.orders + day.orderItemCount,
                        units: updatedDataSales.units + day.unitCount
                    }
                }
            }

            week[j] = {
                totalSales: updatedDataSales.sales, orders: updatedDataSales.orders, units: updatedDataSales.units,
                sales: 0, spend: 0
            }
            newData.forEach(updatedCampaign => {
                week[j].sales = week[j].sales + updatedCampaign.sales
                week[j].spend = week[j].spend + updatedCampaign.spend
            })
            newDataSb.forEach(updatedCampaign => {
                week[j].sales = week[j].sales + updatedCampaign.sales
                week[j].spend = week[j].spend + updatedCampaign.spend
            })
            newDataSd.forEach(updatedCampaign => {
                week[j].sales = week[j].sales + updatedCampaign.sales
                week[j].spend = week[j].spend + updatedCampaign.spend
            })
        }

        tableContent = <PerformanceTable dateArray={dateStrArray} weekData={week} monthLength={monthLength}></PerformanceTable>
    }

    return (
        <div>
            {tableContent}
        </div>
    )
}