import { createSlice } from '@reduxjs/toolkit';

const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        accountId: null,
        accountName: null,
        targetAcos: null,
        avgPrice: null,
        maxBid: null,
        campaignId: null,
        campaignName: null,
        portfolioId: null,
        portfolioName: null,
        isEnableCmpOnly: true,
        selectedDateCampaigns: null
    },
    reducers: {
        updateAccount(state, action) {
            state.accountId = action.payload.id;
            state.accountName = action.payload.accountName;
            state.targetAcos = action.payload.acos;
            state.avgPrice = action.payload.avgPrice;
            state.maxBid = action.payload.maxBid;
        },
        updateAcosState(state, action) {
            state.targetAcos = action.payload.formValue;
        },
        updateAvgPriceState(state, action) {
            state.avgPrice = action.payload.formValue;
        },
        updateMaxBidState(state, action) {
            state.maxBid = action.payload.formValue;
        },
        updateCampaignId(state, action) {
            state.campaignId = action.payload.campaignId
            state.campaignName = action.payload.campaignName
        },
        updatePortfolioId(state, action) {
            state.portfolioId = action.payload.portfolioId
            state.portfolioName = action.payload.portfolioName
        },
        updateIsEnbCmpOnly(state, action) {
            state.isEnableCmpOnly = action.payload.isEnableCmpOnly
        },
        updateSelectedDate(state, action) {
            state.selectedDateCampaigns = action.payload
        }
    },
});

export const accountsReducer = accountsSlice.reducer;
export const { updateAccount, updateAcosState, updateAvgPriceState, updateMaxBidState, updateCampaignId, updatePortfolioId, updateSelectedDate, updateIsEnbCmpOnly } = accountsSlice.actions;