if (process.env.NODE_ENV === 'production') {
    // we are in production, return prod keys
    module.exports = {
        baseUrl: process.env.BASE_URL,
    };
} else {
    // we are in dev, return dev keys
    module.exports = {
        baseUrl: 'http://localhost:3000',
    };
}