export default function PerformanceTable({ dateArray, weekData }) {

    const dateClass = "w-40 font-bold pb-1 px-2 mb-2 border-b"
    const stdRowClass = "w-40 pt-3 pb-1 px-2 border-b"
    const sepRowClass = "w-40 pt-3 pb-1 px-2 mt-4 border-b"


    const values = dateArray.map((date, index) => {

        return (
            <div className="flex flex-col hover:bg-sky-50 p-3 rounded-xl" key={index}>
                <div className={dateClass}>{date}</div>

                <div className={sepRowClass + " font-bold"}>{weekData[index] ? <div>${weekData[index].totalSales.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>
                <div className={stdRowClass}>{weekData[index] ? <div>${(weekData[index].totalSales / 7).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>

                <div className={sepRowClass}>{weekData[index] ? <div>${(weekData[index].totalSales - weekData[index].sales).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>
                <div className={stdRowClass}>{weekData[index] ? <div>${weekData[index].sales.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>
                <div className={stdRowClass}>{weekData[index] ? <div>${weekData[index].spend.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>
                <div className={stdRowClass + " text-gray-500"}>{weekData[index] ? <div>${(weekData[index].spend / 7).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>

                <div className={sepRowClass + " font-bold"}>{weekData[index] ? <div>{(weekData[index].spend / weekData[index].sales * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%</div> : "-"}</div>
                <div className={stdRowClass + " font-bold"}>{weekData[index] ? <div>{(weekData[index].spend / weekData[index].totalSales * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%</div> : "-"}</div>

                <div className={sepRowClass}>{weekData[index] ? <div>{weekData[index].orders.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div> : "-"}</div>
                <div className={stdRowClass}>{weekData[index] ? <div>{weekData[index].units.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div> : "-"}</div>
                <div className={stdRowClass}>{weekData[index] ? <div>${(weekData[index].totalSales / weekData[index].units).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div> : "-"}</div>
            </div>
        )
    })


    return (
        <div className="flex flex-row text-xs p-5">
            <div className="flex flex-col p-3 rounded-xl bg-orange-50">
                <div className={dateClass}>Date Weekly</div>

                <div className={sepRowClass + " font-bold"}>Total Sales</div>
                <div className={stdRowClass}>Sales/Day</div>

                <div className={sepRowClass}>Organic Sales</div>
                <div className={stdRowClass}>Ad Sales</div>
                <div className={stdRowClass}>Ad Spend</div>
                <div className={stdRowClass + " text-gray-500"}>Spend/Day</div>

                <div className={sepRowClass + " font-bold"}>ACOS</div>
                <div className={stdRowClass + " font-bold"}>True ACOS</div>

                <div className={sepRowClass}>Orders</div>
                <div className={stdRowClass}>Units</div>
                <div className={stdRowClass}>Avg Unit Price</div>
            </div>
            <div className="flex flex-row-reverse overflow-scroll">{values}</div>

        </div>
    )
}