import { useFetchAllCampaignsReportQuery, useFetchAllSbCampaignsReportQuery, useFetchAllSdCampaignsReportQuery, useFetchProfilesQuery, useFetchAllSalesReportQuery } from "../../store";
import OverviewTable from './OverviewTable';
import Skeleton from '../Skeleton';

function OverviewList({ pickedDate }) {
    const { data, error, isFetching } = useFetchAllCampaignsReportQuery();
    const { data: dataSb } = useFetchAllSbCampaignsReportQuery();
    const { data: dataSd } = useFetchAllSdCampaignsReportQuery();
    const { data: dataSales, error: errorSales, isFetching: isFetchingSales } = useFetchAllSalesReportQuery();
    const { data: dataProfiles } = useFetchProfilesQuery();

    console.log(pickedDate)

    let tableContent;
    let profileOptions = [];

    if (isFetching || isFetchingSales) {
        tableContent = <Skeleton times={5} className="h-12 w-full" />;
    } else if (error || errorSales) {
        tableContent = <div>Error!!! Please contact Patara</div>
    } else if (data && dataSb && dataSd && dataSales && dataProfiles) {
        profileOptions = dataProfiles.map(profile => {
            return {
                accountName: profile.accountName,
                accountId: profile.accountId
            }
        });
        let accountData = [];
        console.log('Time Now: ', new Date())
        const offset = new Date().getTimezoneOffset()
        const dateNow = new Date();
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 90; i++) {
            let start = new Date();
            let end = new Date();
            start.setDate(dateNow.getDate() - (2 + i));
            end.setDate(dateNow.getDate() - (2 + i));
            start = new Date(start.getTime() - ((offset + 360) * 60 * 1000))
            end = new Date(end.getTime() - ((offset + 360) * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Day ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }

        let startIndex = 0;
        let endIndex = 30;
        startIndex = Math.floor(((dateNow.getTime() - (2 * 1000 * 60 * 60 * 24 + ((offset + 360) * 60 * 1000))) - pickedDate.endDate.getTime()) / (1000 * 60 * 60 * 24));
        endIndex = Math.floor(((dateNow.getTime() - (2 * 1000 * 60 * 60 * 24 + ((offset + 360) * 60 * 1000))) - pickedDate.startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
        console.log("Start Index: " + startIndex + ", End Index: " + endIndex);



        for (let i = 0; i < profileOptions.length; i++) {

            let salesDataAccount = dataSales.find(object => object.accountId === profileOptions[i].accountId)
            let updatedSalesData = {
                ...salesDataAccount,
                unitCount_1: 0,
                orderItemCount_1: 0,
                orderCount_1: 0,
                totalSales_1: 0,
            }
            if (salesDataAccount) {
                salesDataAccount.performance.forEach(performance => {
                    for (let i = startIndex; i < endIndex; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedSalesData = {
                                ...updatedSalesData,
                                unitCount_1: updatedSalesData.unitCount_1 + performance.unitCount,
                                orderItemCount_1: updatedSalesData.orderItemCount_1 + performance.orderItemCount,
                                orderCount_1: updatedSalesData.orderCount_1 + performance.orderCount,
                                totalSales_1: updatedSalesData.totalSales_1 + performance.totalSales,
                            }
                        }
                    }
                });
            }

            let dataAccount = data.filter(campaign => {
                return campaign.accountId === profileOptions[i].accountId;
            });
            let dataSbAccount = dataSb.filter(campaign => {
                return campaign.accountId === profileOptions[i].accountId;
            });
            let dataSdAccount = dataSd.filter(campaign => {
                return campaign.accountId === profileOptions[i].accountId;
            });

            const tableData = dataAccount.map(targeting => {
                let updatedTargeting = {
                    ...targeting,
                    spend_1: 0,
                    sales_1: 0,
                    sales1_1: 0,
                    sales7_1: 0,
                    sales30_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                }

                targeting.performance.forEach(performance => {
                    for (let i = startIndex; i < endIndex; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_1: updatedTargeting.spend_1 + performance.spend,
                                sales_1: updatedTargeting.sales_1 + performance.sales7d,
                                impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                orders_1: updatedTargeting.orders_1 + performance.purchases7d,
                            }
                        }
                    }
                });

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.spend_1 / updatedTargeting.clicks_1).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.spend_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    ctr_1: updatedTargeting.impressions_1 === 0 ? 0 : ((updatedTargeting.clicks_1 / updatedTargeting.impressions_1) * 100).toFixed(2),
                };
            })

            const tableDataSb = dataSbAccount.map(targeting => {
                let updatedTargeting = {
                    ...targeting,
                    spend_1: 0,
                    sales_1: 0,
                    sales1_1: 0,
                    sales7_1: 0,
                    sales30_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                }

                targeting.performance.forEach(performance => {
                    for (let i = startIndex; i < endIndex; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_1: updatedTargeting.spend_1 + performance.cost,
                                sales_1: updatedTargeting.sales_1 + performance.attributedSales14d,
                                impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                orders_1: updatedTargeting.orders_1 + performance.attributedConversions14d,
                            }
                        }
                    }
                });

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.spend_1 / updatedTargeting.clicks_1).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.spend_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    ctr_1: updatedTargeting.impressions_1 === 0 ? 0 : ((updatedTargeting.clicks_1 / updatedTargeting.impressions_1) * 100).toFixed(2),
                };
            })

            const tableDataSd = dataSdAccount.map(targeting => {
                let updatedTargeting = {
                    ...targeting,
                    spend_1: 0,
                    sales_1: 0,
                    sales1_1: 0,
                    sales7_1: 0,
                    sales30_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                }

                targeting.performance.forEach(performance => {
                    for (let i = startIndex; i < endIndex; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_1: updatedTargeting.spend_1 + performance.cost,
                                sales_1: updatedTargeting.sales_1 + performance.attributedSales14d,
                                impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                orders_1: updatedTargeting.orders_1 + performance.attributedConversions14d,
                            }
                        }
                    }
                });

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.spend_1 / updatedTargeting.clicks_1).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.spend_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    ctr_1: updatedTargeting.impressions_1 === 0 ? 0 : ((updatedTargeting.clicks_1 / updatedTargeting.impressions_1) * 100).toFixed(2),
                };
            })

            accountData[i] = {
                accountId: profileOptions[i].accountId,
                accountName: profileOptions[i].accountName,
                dailyBudget: 0,
                acos_1: 0,
                spend_1: 0,
                orders_1: 0,
                sales_1: 0,
                clicks_1: 0,
                impressions_1: 0,
                ctr_1: 0,
                cpc_1: 0,
                cvr_1: 0,
                unitCount_1: updatedSalesData.unitCount_1,
                orderItemCount_1: updatedSalesData.orderItemCount_1,
                orderCount_1: updatedSalesData.orderCount_1,
                totalSales_1: updatedSalesData.totalSales_1,
                tacos_1: 0,
            };

            tableData.forEach(campaign => {
                if (campaign.campaignStatus === "ENABLED") accountData[i].dailyBudget = accountData[i].dailyBudget + campaign.campaignBudgetAmount;
                accountData[i].spend_1 = accountData[i].spend_1 + campaign.spend_1;
                accountData[i].orders_1 = accountData[i].orders_1 + campaign.orders_1;
                accountData[i].sales_1 = accountData[i].sales_1 + campaign.sales_1;
                accountData[i].clicks_1 = accountData[i].clicks_1 + campaign.clicks_1;
                accountData[i].impressions_1 = accountData[i].impressions_1 + campaign.impressions_1;
            });

            tableDataSb.forEach(campaign => {
                if (campaign.campaignStatus === "enabled") accountData[i].dailyBudget = accountData[i].dailyBudget + campaign.campaignBudget;
                accountData[i].spend_1 = accountData[i].spend_1 + campaign.spend_1;
                accountData[i].orders_1 = accountData[i].orders_1 + campaign.orders_1;
                accountData[i].sales_1 = accountData[i].sales_1 + campaign.sales_1;
                accountData[i].clicks_1 = accountData[i].clicks_1 + campaign.clicks_1;
                accountData[i].impressions_1 = accountData[i].impressions_1 + campaign.impressions_1;
            });

            tableDataSd.forEach(campaign => {
                if (campaign.campaignStatus === "ENABLED") accountData[i].dailyBudget = accountData[i].dailyBudget + campaign.campaignBudget;
                accountData[i].spend_1 = accountData[i].spend_1 + campaign.spend_1;
                accountData[i].orders_1 = accountData[i].orders_1 + campaign.orders_1;
                accountData[i].sales_1 = accountData[i].sales_1 + campaign.sales_1;
                accountData[i].clicks_1 = accountData[i].clicks_1 + campaign.clicks_1;
                accountData[i].impressions_1 = accountData[i].impressions_1 + campaign.impressions_1;
            });

            accountData[i].acos_1 = accountData[i].sales_1 === 0 ? 0 : (accountData[i].spend_1 / accountData[i].sales_1 * 100).toFixed(2);
            accountData[i].ctr_1 = accountData[i].impressions_1 === 0 ? 0 : (accountData[i].clicks_1 / accountData[i].impressions_1 * 100).toFixed(2);
            accountData[i].cpc_1 = accountData[i].clicks_1 === 0 ? 0 : (accountData[i].spend_1 / accountData[i].clicks_1).toFixed(2);
            accountData[i].cvr_1 = accountData[i].orders_1 === 0 ? 0 : (accountData[i].orders_1 / accountData[i].clicks_1 * 100).toFixed(2);
            accountData[i].tacos_1 = accountData[i].totalSales_1 === 0 ? 0 : (accountData[i].spend_1 / accountData[i].totalSales_1 * 100).toFixed(2);
            accountData[i].spendPerDay = accountData[i].spend_1 / (endIndex - startIndex)
        }
        const filteredAccountData = accountData.filter(data => data.totalSales_1 > 0)
        tableContent = (
            <OverviewTable data={filteredAccountData} />
        );

    }

    return (
        <div className="flex flex-col" >
            <div className="mx-5 my-1 text-sm">
                {tableContent}
            </div>
        </div >
    );
}

export default OverviewList;