import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { targetingApi } from './apis/targetingApi';
import { campaignApi } from './apis/campaignApi';
import { retrieveApi } from './apis/retrieveApi';
import { reportingApi } from './apis/reportingApi';
import { accountsReducer } from './slices/accountsSlice';
import { pataraBidsReducer } from './slices/pataraBidsSlice';
import { searchTermApi } from './apis/searchTermApi';
import { salesReportApi } from './apis/spSalesReportApi';
import { profilesApi } from './apis/profilesApi';
import { tasksApi } from './apis/tasksApi';
import { listingsApi } from './apis/listingsApi';

export const store = configureStore({
    reducer: {
        [targetingApi.reducerPath]: targetingApi.reducer,
        [retrieveApi.reducerPath]: retrieveApi.reducer,
        [reportingApi.reducerPath]: reportingApi.reducer,
        [campaignApi.reducerPath]: campaignApi.reducer,
        [searchTermApi.reducerPath]: searchTermApi.reducer,
        [salesReportApi.reducerPath]: salesReportApi.reducer,
        [profilesApi.reducerPath]: profilesApi.reducer,
        [tasksApi.reducerPath]: tasksApi.reducer,
        [listingsApi.reducerPath]: listingsApi.reducer,
        accounts: accountsReducer,
        patarabids: pataraBidsReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(targetingApi.middleware)
            .concat(retrieveApi.middleware)
            .concat(reportingApi.middleware)
            .concat(campaignApi.middleware)
            .concat(searchTermApi.middleware)
            .concat(salesReportApi.middleware)
            .concat(profilesApi.middleware)
            .concat(tasksApi.middleware)
            .concat(listingsApi.middleware);
    }
});

setupListeners(store.dispatch);

export {
    updateAccount, updateCampaignId, updatePortfolioId, updateAcosState, updateAvgPriceState,
    updateMaxBidState, updateSelectedDate, updateIsEnbCmpOnly
} from './slices/accountsSlice';
export { updatePataraBids } from './slices/pataraBidsSlice';

export {
    useFetchCampaignsReportQuery, useFetchAllCampaignsReportQuery,
    useFetchAllSbCampaignsReportQuery, useFetchSbCampaignsReportQuery, useFetchCampaignLastUpdatedQuery,
    useFetchSdCampaignsReportQuery, useFetchAllSdCampaignsReportQuery, useUpdateCampaignBudgetMutation
} from './apis/campaignApi';

export { useFetchTargetingsQuery, useFetchTargetingsSbQuery, useFetchTargetingsSdQuery, useFetchTargetingsListQuery, useFetchTargetingsListSbQuery, useFetchTargetingsListSdQuery, useUpdateBidMutation, useUpdateBidSbMutation, useUpdateBidSdMutation, useGetPataraBidQuery } from './apis/targetingApi';
export {
    useFetchProfilesQuery, useFetchCampaignsQuery, useFetchSbCampaignsQuery, useFetchSdCampaignsQuery, useFetchUserQuery,
    useFetchPortfoliosQuery
} from './apis/retrieveApi';
export { useRequestReportsMutation, useRequestReports12Mutation } from './apis/reportingApi';
export { useUpdateAcosMutation, useUpdateAvgPriceMutation, useUpdateMaxBidMutation } from './apis/profilesApi';
export { useFetchSearchTermsQuery } from './apis/searchTermApi';
export { useFetchSalesReportQuery, useFetchAllSalesReportQuery } from './apis/spSalesReportApi';
export { useCreateTaskMutation, useGetTasksQuery, useDeleteTaskMutation } from './apis/tasksApi'
export { useFetchListingsQuery } from './apis/listingsApi'