import { useSelector } from "react-redux";
import { useState } from 'react';
import PerformanceList from "./PerformanceList"
import PerformanceListM from "./PerformanceListMonthly"
import SelectAccountDiv from "../SelectAccountDiv"
import ToggleSwitch from "./PerformanceToggle";

function PerformancePage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [isWeekly, setIsWeekly] = useState(true)
    console.log(isWeekly)
    return (

        <div>
            {accountId
                ? <div className="my-5">
                    <div className="flex justify-center my-3 font-bold">Organic Sales & Advertising Performance</div>
                    <div className="flex flex-row px-5">
                        <div className="px-3">Monthly</div>
                        <ToggleSwitch isWeekly={isWeekly} setIsWeekly={setIsWeekly}></ToggleSwitch>
                        <div className="px-3">Weekly {isWeekly}</div>
                    </div>
                    {isWeekly ?
                        <PerformanceList accountId={accountId}></PerformanceList>
                        :
                        <PerformanceListM accountId={accountId}></PerformanceListM>}
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }

        </div>
    )
}

export default PerformancePage