import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const salesReportApi = createApi({
    reducerPath: 'salesreport',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchSalesReport: builder.query({
                query: (accountId) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/salesReport',
                        method: 'GET',
                        params: {
                            accountId
                        }
                    }
                }
            }),
            fetchAllSalesReport: builder.query({
                query: () => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/allSalesReport',
                        method: 'GET',
                        params: {
                        }
                    }
                }
            }),
        }
    }
});

export { salesReportApi };
export const { useFetchSalesReportQuery, useFetchAllSalesReportQuery } = salesReportApi;