//import inventory2 from '../photos/inventory2.png';
//import catalog from '../photos/catalog.webp';
import customer from '../photos/customer.png';
import ts from '../photos/ts.png';
//import ads from '../photos/ads.png';
import Link from "../Link";

function HomePage() {

    const desc = [
        "Advertising on Amazon has been a significant tool to increase sales on Amazon.com for years, and its importance has been growing over time. "
        + "In order to maintain a sales goal on Amazon, sellers must advertise their products effectively based on their revenue and profit goals. "
        + "Patara helps sellers advertise their products with optimized ad spend and revenue. "
        + "Patara analyzes your catalog entirely and pinpoint best products to advertise to maximize the revenue and minimize the spend.",

        "Aside from perfectly setting up the campaigns, one of the most essential techniques is optimizing ad campaigns on a regular and proper basis. "
        + "Incorrectly updating advertising campaigns will result in revenue loss and performance issues. This will prevent you from making a profit or even competing in your niche as a seller. "
        + "Patara uses a semi-automated optimization model to "
        + "manually review the performance of ad campaigns and take appropriate action and "
        + "to take into consideration a huge amount of data while optimizing the campaigns"
    ]

    return (
        <div className="flex flex-col overflow-scroll">
            <div className="flex px-2 my-2 justify-center font-bold text-white tracking-wider bg-yellow-400">
                We help Amazon sellers grow sales!
                <Link key={'/services'} to={'/services'}
                    className='ml-2 text-white'
                    activeClassName='ml-2 text-white' >{'Explore our services!'}
                </Link>
            </div>
            <div className='flex flex-col items-center'>
                <div className="flex flex-col w-[75vw] my-2 justify-center text-s text-justify items-center">
                    <div className="h-[25vh] w-[75vw] flex"><img src={ts} alt="catalog" /></div>
                    <div className="p-4 my-2 w-[75vw] shadow-lg rounded-lg">{desc[0]}</div>
                </div>
                <div className="flex flex-col w-[75vw] justify-center text-s text-justify items-center">
                    <div className="h-[25vh] w-[75vw] flex"><img src={customer} alt="catalog" /></div>
                    <div className="p-4 my-2 w-[75vw] shadow-lg rounded-lg border border-gray-100">{desc[1]}</div>
                </div>
            </div>
        </div>
    );
}
export default HomePage;