import BudgetForm from "./BudgetForm";
import AcosDisplay from "./AcosDisplayDiv";

export const GROUPED_COLUMNS = [
    {
        Header: 'Campaign Name',
        Footer: 'Campaign Name',
        accessor: 'campaignName',
        Cell: ({ row, value }) => {
            const lastUpdated = row.original.lastUpdated
            const budgetUpdated = row.original.budgetUpdated
            return (
                <div className="pl-1 break-words">
                    {value}
                    <div className="text-gray-300 text-xxs">{"Campaign ID: " + row.original.campaignId}</div>
                    <div className="border rounded-lg my-1 p-1">
                        <div className="text-gray-700 text-xxs">{lastUpdated ? "Last Updated: " + lastUpdated.date : "Last Updated: Never"}</div>
                    </div>
                    <div className="border rounded-lg p-1">
                        <div className="text-gray-700 text-xxs">{budgetUpdated ? "Budget Updated: " + budgetUpdated.date + " ----- $" + budgetUpdated.oldBudget + " >>> $" + budgetUpdated.newBudget : "Budget Updated: Never"}</div>
                    </div>
                </div>
            )
        }
    },
    {
        Header: 'Budget',
        Footer: 'Budget',
        accessor: 'currBudget',
        Cell: ({ row, value }) => <div>
            <div className='flex justify-center'><BudgetForm target={value} char={"$"} campaignId={row.original.campaignId} campaignType="sb"></BudgetForm></div>
            <div className='flex justify-center text-xxs text-gray-400'>${row.original.spendPerDay.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
        </div>
    },
    {
        Header: 'ACOS',
        Footer: 'ACOS',
        accessor: 'acos_1',
        sortDescFirst: true,
        Cell: ({ value, row }) => {
            return <AcosDisplay value={value} row={row}></AcosDisplay>
        }
    },
    {
        Header: 'Spend',
        Footer: 'Spend',
        accessor: 'spend_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Orders',
        Footer: 'Orders',
        accessor: 'orders_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Sales (14d)',
        Footer: 'Sales (14d)',
        accessor: 'sales_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Clicks',
        Footer: 'Clicks',
        accessor: 'clicks_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Impress',
        Footer: 'Impress',
        accessor: 'impressions_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'CTR %',
        Footer: 'CTR %',
        accessor: 'ctr_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{value === 0 ? '-' : (value + "%")}</div>
    },
    {
        Header: 'CPC',
        Footer: 'CPC',
        accessor: 'cpc_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
    },
]