import { useState, useEffect } from "react";
import { ImCancelCircle, ImCheckmark, ImPencil } from "react-icons/im";


function DashboardForm({ accountId, updateState, updateValue, target, title, char }) {

    const [formValue, setFormValue] = useState(target);

    const [submittable, setSubmittable] = useState(true);
    const [updateBidContentVisibility, setUpdateBidContentVisibility] = useState('edit-button');

    useEffect(() => {
        setFormValue(target)
        setUpdateBidContentVisibility('edit-button')
    }, [target]);

    const classname = 'w-14 mr-1 border-r border-t border-b rounded-r-lg px-2 focus:outline-0';
    const [inputClass, setInputClass] = useState(classname);

    const classname2 = 'flex justify-center bg-gray-100 border-l border-t border-b rounded-l-lg px-1 items-center w-6';
    const [inputClass2, setInputClass2] = useState(classname2);

    const onApproveClick = () => {
        if (submittable) {
            //create an api to update the existing target acos
            updateValue({ id: accountId, targetValue: formValue });
            updateState({ formValue })
            setInputClass(classname);
            setInputClass2(classname2);
            setUpdateBidContentVisibility('edit-button');
        } else {
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
        }
    };

    const onCancelClick = () => {
        setInputClass(classname);
        setInputClass2(classname2);
        setUpdateBidContentVisibility('edit-button');
        setFormValue(target);
    };

    const onEditClick = () => {
        setUpdateBidContentVisibility('form');
    };

    const onChange = (event) => {
        const value = event.target.value;
        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 1 || value > 1000) {
            //setDisabled(true);
            setInputClass((classname + ' border-red-500'));
            setInputClass2((classname2 + ' border-red-500'));
            setSubmittable(false);
            //make input border red
        } else if (!isNaN(value) && value.length < 6) {
            //setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            setInputClass2((classname2 + ' border-green-600'));
            setSubmittable(true);
            //make input border gray again
        }
        if (!isNaN(value) && value.length < 6) {
            setFormValue(value);
        }
    };

    return (
        <div className='mx-3 px-5 py-3 rounded-lg bg-sky-200  w-52' >
            {title}
            <div className="flex flex-row mt-2">
                {updateBidContentVisibility === 'edit-button'
                    ? <div className="flex items-center justify-center drop-shadow w-20 mr-1 border rounded-lg bg-white px-2">
                        <div >
                            {char === '%' ? target + char : char + target}
                        </div>
                    </div>
                    : <div className="flex flex-row drop-shadow">

                        <div className={inputClass2}>
                            {char}
                        </div>
                        <input
                            className={inputClass}
                            value={formValue}
                            onChange={onChange} >
                        </input>
                    </div>
                }
                <button
                    onClick={onEditClick}
                    className='mx-1'
                    hidden={updateBidContentVisibility !== 'edit-button'}>
                    <ImPencil />
                </button>
                <button
                    onClick={onApproveClick}
                    className='mx-1'
                    hidden={updateBidContentVisibility !== 'form'}>
                    <ImCheckmark />
                </button>
                <button
                    onClick={onCancelClick}
                    className='mx-1'
                    hidden={updateBidContentVisibility !== 'form'}>
                    <ImCancelCircle />
                </button>
            </div>
        </div>
    )

}

export default DashboardForm;