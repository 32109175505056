import { useState } from "react"
import { useCreateTaskMutation } from "../../store"

export default function NewTask({ setShowModal, accountId, refetch }) {
    const [task, setTask] = useState("")
    const [desc, setDesc] = useState("")
    let taskClassName = "rounded-xl border p-2 my-2"
    const [taskClass, setTaskClass] = useState(taskClassName)
    let descClassName = "rounded-xl border px-2 py-1 my-2"
    const [descClass, setDescClass] = useState(descClassName)
    const [createTask] = useCreateTaskMutation()

    async function onChange() {
        //send form data to database
        console.log("Title: ", task, ", Desc: ", desc)
        if (desc.length > 10 && task.length > 5) {
            console.log("Submitting - ", "Title: ", task, ", Desc: ", desc)
            await createTask({ task, desc, accountId })
            refetch()
            setShowModal(false);
        } else if (task.length < 6) {
            setTaskClass(taskClassName + " border-red-500")
            console.log("title should be longer than 5 characters")
        } else if (desc.length < 11) {
            setDescClass(descClassName + " border-red-500")
            console.log("please provide a description")
        } else {
            console.log("bir hata var gibi")
        }

    };

    function onTaskChange(e) {
        setTaskClass(taskClassName)
        setTask(e.target.value)
    }

    function onDescChange(e) {
        setDescClass(descClassName)
        setDesc(e.target.value)
    }

    function onCancel() {
        setShowModal(false);
    };

    return (
        <div>
            <div className="flex mb-5 font-bold justify-center">Create a new task</div>
            <div className="flex flex-col my-8">
                <div className="text-gray-500 text-xs">Task Name</div>
                <input className={taskClass} value={task} placeholder={"Provide a short description (at least 5 characters)"} onChange={e => onTaskChange(e)}></input>
            </div>
            <div className="flex flex-col my-8">
                <div className="text-gray-500 text-xs">Task Description</div>
                <textarea className={descClass} rows={5} placeholder={"Provide a detailed explanation here (at least 10 characters)"} onChange={e => onDescChange(e)}></textarea>
            </div>
            <div className="flex justify-end">
                <button className="rounded-xl shadow p-2 text-white bg-red-600 mx-2" onClick={onCancel} >Cancel</button>
                <button className="rounded-xl shadow p-2 text-white bg-green-600" onClick={onChange} >Create Now</button>
            </div>

        </div>
    )
}