import { useFetchTargetingsSbQuery, useFetchTargetingsListSbQuery } from "../../store";
import TargetingTable from './TargetingTableSb';
import Skeleton from '../Skeleton';
import Panel from "../Panel";

function TargetingList({ campaignId, accountId }) {
    const { data, error, isFetching } = useFetchTargetingsSbQuery(campaignId);
    const { data: dataList } = useFetchTargetingsListSbQuery({ id: campaignId, accountId })

    let columns = [{ label: '7 Days', content: null }, { label: '30 Days', content: null }, { label: '60 Days', content: null }, { label: '90 Days', content: null }];
    let tableContent, performanceContent, finalContent;
    if (isFetching) {
        finalContent = columns.map((column, index) => {
            return (
                <Skeleton times={1} key={index} className="h-12 w-96 mx-4" />
            );
        })
        tableContent = <Skeleton times={5} className="h-12 w-full" />;
    } else if (error) {
        tableContent = <div>Error!!! Please contact Patara</div>
    } else if (data && dataList) {
        //console.log(dataList)
        //13 adet hafta başlangıç ve bitiş tarihleri
        const dateNow = new Date();
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 90; i++) {
            let start = new Date();
            let end = new Date();
            const offset = new Date().getTimezoneOffset()
            start.setDate(dateNow.getDate() - (2 + i));
            end.setDate(dateNow.getDate() - (2 + i));
            start = new Date(start.getTime() + (offset * 60 * 1000))
            end = new Date(end.getTime() + (offset * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Week ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }
        console.log("Day End: ", dateArray[0])
        console.log("Day Start 7: ", dateArray[6])
        console.log("Day Start 30: ", dateArray[29])

        /*
        //PAUSED OLAN TARGETINGLERI DATA ARRAYİ İÇİNDEN ÇIKART. AŞAĞIDA YENİ ARRAY İLE İŞLEM YAP
        const dataEnabled = data.filter(targeting => {
            return targeting.adKeywordStatus === "ENABLED";
        });
        */


        if (data.length > 0) {
            //console.log(data);
            const tableData = data.map(targeting => {
                //console.log(targeting)
                const apiTarget = dataList.find(target => Number(target.targetId) === targeting.targetId)
                const apiKeyword = dataList.find(target => Number(target.keywordId) === targeting.targetId)
                let bid = 0
                if (apiTarget) {
                    bid = apiTarget.bid
                } else if (apiKeyword) {
                    bid = apiKeyword.bid
                }
                //console.log("bid from api: ", bid)
                let updatedTargeting = {
                    ...targeting,
                    cost_1: 0,
                    sales_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                    bid
                }

                targeting.performance.forEach(performance => {

                    for (let i = 0; i < 7; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_1: updatedTargeting.cost_1 + performance.cost,
                                sales_1: updatedTargeting.sales_1 + performance.attributedSales14d,
                                impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                orders_1: updatedTargeting.orders_1 + performance.attributedConversions14d,
                            }
                        }
                        //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.targeting + ", Spend: " + updatedTargeting.cost_1)
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_4: updatedTargeting.cost_1,
                    sales_4: updatedTargeting.sales_1,
                    impressions_4: updatedTargeting.impressions_1,
                    clicks_4: updatedTargeting.clicks_1,
                    orders_4: updatedTargeting.orders_1,
                }

                targeting.performance.forEach(performance => {

                    for (let i = 7; i < 30; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_4: updatedTargeting.cost_4 + performance.cost,
                                sales_4: updatedTargeting.sales_4 + performance.attributedSales14d,
                                impressions_4: updatedTargeting.impressions_4 + performance.impressions,
                                clicks_4: updatedTargeting.clicks_4 + performance.clicks,
                                orders_4: updatedTargeting.orders_4 + performance.attributedConversions14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_8: updatedTargeting.cost_4,
                    sales_8: updatedTargeting.sales_4,
                    impressions_8: updatedTargeting.impressions_4,
                    clicks_8: updatedTargeting.clicks_4,
                    orders_8: updatedTargeting.orders_4,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 30; i < 60; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_8: updatedTargeting.cost_8 + performance.cost,
                                sales_8: updatedTargeting.sales_8 + performance.attributedSales14d,
                                impressions_8: updatedTargeting.impressions_8 + performance.impressions,
                                clicks_8: updatedTargeting.clicks_8 + performance.clicks,
                                orders_8: updatedTargeting.orders_8 + performance.attributedConversions14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    cost_13: updatedTargeting.cost_8,
                    sales_13: updatedTargeting.sales_8,
                    impressions_13: updatedTargeting.impressions_8,
                    clicks_13: updatedTargeting.clicks_8,
                    orders_13: updatedTargeting.orders_8,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 60; i < 90; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                cost_13: updatedTargeting.cost_13 + performance.cost,
                                sales_13: updatedTargeting.sales_13 + performance.attributedSales14d,
                                impressions_13: updatedTargeting.impressions_13 + performance.impressions,
                                clicks_13: updatedTargeting.clicks_13 + performance.clicks,
                                orders_13: updatedTargeting.orders_13 + performance.attributedConversions14d,
                            }
                        }
                    }
                });

                const histLeng = targeting.history.length
                let lastUpdate = null
                if (histLeng > 0) {
                    lastUpdate = targeting.history[histLeng - 1]
                }

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.cost_1 / updatedTargeting.clicks_1).toFixed(2),
                    cpc_4: (updatedTargeting.cost_4 / updatedTargeting.clicks_4).toFixed(2),
                    cpc_8: (updatedTargeting.cost_8 / updatedTargeting.clicks_8).toFixed(2),
                    cpc_13: (updatedTargeting.cost_13 / updatedTargeting.clicks_13).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.cost_1 / updatedTargeting.sales_1) * 100).toFixed(2),
                    acos_4: updatedTargeting.sales_4 === 0 ? 0 : ((updatedTargeting.cost_4 / updatedTargeting.sales_4) * 100).toFixed(2),
                    acos_8: updatedTargeting.sales_8 === 0 ? 0 : ((updatedTargeting.cost_8 / updatedTargeting.sales_8) * 100).toFixed(2),
                    acos_13: updatedTargeting.sales_13 === 0 ? 0 : ((updatedTargeting.cost_13 / updatedTargeting.sales_13) * 100).toFixed(2),
                    lastUpdate
                };
            })

            let spend0 = 0, sales0 = 0, spend1 = 0, sales1 = 0, spend2 = 0, sales2 = 0, spend3 = 0, sales3 = 0;

            tableData.forEach(item => {
                spend0 = spend0 + item.cost_1;
                sales0 = sales0 + item.sales_1;
                spend1 = spend1 + item.cost_4;
                sales1 = sales1 + item.sales_4;
                spend2 = spend2 + item.cost_8;
                sales2 = sales2 + item.sales_8;
                spend3 = spend3 + item.cost_13;
                sales3 = sales3 + item.sales_13;
            });

            //TABLE CONTENT OLUŞTURULMADAN ÖNCE ORT FİYAT HESAPLAMASI YAPILACAK.
            //TOPLAM SATIŞ / SİPARİŞ ADEDİ
            tableContent = (
                <TargetingTable data={tableData} campaignId={campaignId} />
                //<StickyTable data={tableData} />
            );

            const summary0 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend0.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">Sales: ${Number(sales0.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">ACOS: {Number((spend0 / sales0 * 100).toFixed(2)).toLocaleString()}%</div>
            </div>
            const summary1 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend1.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">Sales: ${Number(sales1.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">ACOS: {Number((spend1 / sales1 * 100).toFixed(2)).toLocaleString()}%</div>
            </div>
            const summary2 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend2.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">Sales: ${Number(sales2.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">ACOS: {Number((spend2 / sales2 * 100).toFixed(2)).toLocaleString()}%</div>
            </div>
            const summary3 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend3.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">Sales: ${Number(sales3.toFixed(2)).toLocaleString()}</div>
                <div className="mx-3">ACOS: {Number((spend3 / sales3 * 100).toFixed(2)).toLocaleString()}%</div>
            </div>

            columns = [
                { label: '7 Days', content: summary0 },
                { label: '30 Days', content: summary1 },
                { label: '60 Days', content: summary2 },
                { label: '90 Days', content: summary3 }
            ];

            performanceContent = columns.map((column, index) => {
                return (
                    <div key={index} className="px-3 py-1 mx-4 shadow rounded-lg text-white bg-sky-800">
                        <div className="mb-2 pb-2 font-bold border-b-2 justify-center flex">{column.label}</div>
                        <div className="text-xs">{column.content}</div>
                    </div>
                );
            })

            finalContent = <Panel >
                <div className="flex flex-row overflow-scroll justify-between">
                    {performanceContent}
                </div>
            </Panel >
        }
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-row px-5 py-1">
                {finalContent}
            </div>
            <div className="mx-5 my-1 text-sm">
                {tableContent}
            </div>
        </div >
    );
}

export default TargetingList;