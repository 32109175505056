import { useState } from "react"
import { FcUndo, FcCheckmark } from "react-icons/fc";
import { MdDeleteForever } from "react-icons/md";
import { useDeleteTaskMutation } from "../../store";

export default function DeleteTask({ id, refetch }) {
    const [showConfirm, setShowConfirm] = useState(false)
    const [deleteTask] = useDeleteTaskMutation()

    async function onConfirm() {
        console.log(id)
        await deleteTask({ id })
        refetch()
        setShowConfirm(false)
    }

    return (
        <div>
            {showConfirm
                ? <div className="flex flex-col">
                    Confirm?
                    <div className="flex flex-row justify-between px-2 text-lg">
                        <button onClick={() => setShowConfirm(false)}><FcUndo></FcUndo></button >
                        <button onClick={onConfirm}><FcCheckmark></FcCheckmark></button>
                    </div>
                </div >
                : <div>
                    <button onClick={() => setShowConfirm(true)}>
                        <div className="flex flex-row items-center">
                            <div className="mr-2">Delete</div>
                            <div className="text-xl"><MdDeleteForever></MdDeleteForever></div>
                        </div>
                    </button>
                </div>
            }
        </div>
    )
}