import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const tasksApi = createApi({
    reducerPath: 'tasks',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            createTask: builder.mutation({
                query: ({ task, desc, accountId }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/createTask',
                        method: 'POST',
                        body: {
                            task,
                            desc,
                            accountId
                        }
                    }
                }
            }),
            getTasks: builder.query({
                query: ({ accountId }) => {
                    return {
                        url: '/api/getTasks',
                        method: 'GET',
                        params: {
                            accountId
                        }
                    }
                }
            }),
            deleteTask: builder.mutation({
                query: ({ id }) => { // DO NOT FORGET TO PROVIDE ARGS IN THE COMPONENT !!!!!
                    return {
                        url: '/api/deleteTask',
                        method: 'POST',
                        body: {
                            id
                        }
                    }
                }
            }),
        }
    }
});

export { tasksApi };
export const { useCreateTaskMutation, useGetTasksQuery, useDeleteTaskMutation } = tasksApi;