import Link from "./Link";
import { useFetchUserQuery } from "../store";

function Sidebar() {
    const { data, error } = useFetchUserQuery();
    let renderedLinks;
    if (error) {
        console.log('fetch user error!!!');
        renderedLinks = <div>User not authorized</div>
    } else if (data) {
        const links = [
            { label: 'Optimization', path: '/targeting' },
            { label: 'Optimization2', path: '/targeting2' },
            { label: 'Optimization SB', path: '/targetingsb' },
            { label: 'Optimization SD', path: '/targetingsd' },
            { label: 'Campaigns', path: '/campaigns' },
            { label: 'Campaigns SB', path: '/campaignssb' },
            { label: 'Campaigns SD', path: '/campaignssd' },
            { label: 'Search Terms', path: '/searchterms' },
            { label: 'Dashboard', path: '/dashboard' },
            { label: 'Overview', path: '/overview' },
            { label: 'Performance', path: '/performance' },
            { label: 'Listings', path: '/listings' },
            //{ label: 'Patarians', path: '/patarians' }
        ];

        renderedLinks = links.map((link) => {
            return <Link key={link.path} to={link.path} className='mx-4' activeClassName='font-bold border-b-4 border-black-500' >{link.label}</Link>
        });
    }


    return (<div className="px-5 py-3 overflow-y-scroll flex flex-row items-center bg-sky-50 border-t" >
        {renderedLinks}
    </div>);
};

export default Sidebar;