import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const profilesApi = createApi({
    reducerPath: 'profiles',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            updateAcos: builder.mutation({
                query: ({ id, targetValue }) => {
                    return {
                        url: '/api/updateAcos',
                        method: 'POST',
                        body: {
                            id,
                            targetValue
                        }
                    }
                }
            }),
            updateAvgPrice: builder.mutation({
                query: ({ id, targetValue }) => {
                    return {
                        url: '/api/updateAvgPrice',
                        method: 'POST',
                        body: {
                            id,
                            targetValue
                        }
                    }
                }
            }),
            updateMaxBid: builder.mutation({
                query: ({ id, targetValue }) => {
                    return {
                        url: '/api/updateMaxBid',
                        method: 'POST',
                        body: {
                            id,
                            targetValue
                        }
                    }
                }
            })
        }
    }
});

export { profilesApi };
export const { useUpdateAcosMutation, useUpdateAvgPriceMutation, useUpdateMaxBidMutation } = profilesApi;