import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './keys';

const listingsApi = createApi({
    reducerPath: 'listings',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints(builder) {
        return {
            fetchListings: builder.query({
                query: ({ accountId }) => {
                    return {
                        url: '/api/fetchListings',
                        method: 'GET',
                        params: {
                            accountId
                        }
                    }
                }
            }),
        }
    }
});

export { listingsApi };
export const { useFetchListingsQuery } = listingsApi;