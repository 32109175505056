//import React from 'react';
import { updateIsEnbCmpOnly } from "../store";
import "./campaignEnableToggle.css"
import { useSelector, useDispatch } from 'react-redux';

function ToggleSwitch() {
    const dispatch = useDispatch()
    const isEnabledOnly = useSelector(state => state.accounts.isEnableCmpOnly)

    const onChange = (event) => {
        dispatch(updateIsEnbCmpOnly({ isEnableCmpOnly: event.target.checked }))
    }
    return (
        <label className="switch">
            <input type="checkbox" onChange={onChange} defaultChecked={isEnabledOnly}></input>
            <span className="slider round"></span>
        </label>
    );

}

export default ToggleSwitch;