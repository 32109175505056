import { useFetchCampaignsReportQuery, useFetchSalesReportQuery, useFetchSbCampaignsReportQuery, useFetchSdCampaignsReportQuery } from "../../store";
import Skeleton from '../Skeleton';
import PerformanceTable from "./PerformanceTable"

export default function PerformanceList({ accountId }) {
    const { data, isFetching } = useFetchCampaignsReportQuery(accountId);
    const { data: dataSb, isFetching: isFetchingSb } = useFetchSbCampaignsReportQuery(accountId);
    const { data: dataSd, isFetching: isFetchingSd } = useFetchSdCampaignsReportQuery(accountId);
    const { data: dataSales, isFetching: isFetchingSales } = useFetchSalesReportQuery(accountId);

    let tableContent

    const weeksToDisplay = 26
    const dateNow = new Date();
    const offset = new Date().getTimezoneOffset()
    const daysSinceLastWeek = new Date(dateNow.getTime() + (offset * 60 * 1000)).getDay();
    //console.log(daysSinceLastWeek)
    let dateArray = [], dateStrArray = [], dateStartArray = [], dateEndArray = [];
    for (let i = 0; i < weeksToDisplay; i++) {
        let start = new Date();
        let end = new Date();

        //console.log("offset ", offset)
        start.setDate(dateNow.getDate() - (daysSinceLastWeek + 7 + 7 * i));
        end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + 7 * i));
        start = new Date(start.getTime() + (offset * 60 * 1000))
        end = new Date(end.getTime() + (offset * 60 * 1000))
        dateStartArray[i] = start.toISOString().split('T')[0];
        dateEndArray[i] = end.toISOString().split('T')[0];
        dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
        //console.log(dateArray[i], ", Start: ", start, ", End: ", end)
        dateStrArray[i] = start.toLocaleString('default', { month: 'short' }) + " " + start.getDate()
            + " - "
            + end.toLocaleString('default', { month: 'short' }) + " " + end.getDate();
    }

    let week = []
    tableContent = <div className="shadow border p-2 m-3 rounded-xl">Data cannot be fetched at the moment. Contact admin for assistance.</div>
    if (isFetching || isFetchingSales || isFetchingSb || isFetchingSd) {
        tableContent = <div className="shadow p-2 m-3 rounded-xl"><Skeleton times={10} className="h-10 w-full" /></div>;
    } else if (data && dataSales && dataSb && dataSd) {

        for (let j = 0; j < weeksToDisplay; j++) {
            let dayArray = [], dayStartArray = [], dayEndArray = [];
            const offset = new Date().getTimezoneOffset()
            for (let i = 0; i < 7; i++) {
                let start = new Date();
                let end = new Date();
                start.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + i + j * 7));
                end.setDate(dateNow.getDate() - (daysSinceLastWeek + 1 + i + j * 7));
                start = new Date(start.getTime() + ((offset) * 60 * 1000))
                end = new Date(end.getTime() + ((offset) * 60 * 1000))
                dayStartArray[i] = start.toISOString().split('T')[0];
                dayEndArray[i] = end.toISOString().split('T')[0];
                dayArray[i] = dayStartArray[i] + "-" + dayEndArray[i];
                //console.log('Day ', i, ': Start Date: ', dayStartArray[i], ', End Date: ', dayEndArray[i]);
            }

            //buradaki newData array olacak. her hafta için yeni bir obje açılacak.
            const newData = data.map(campaign => {
                let updatedCampaign = {
                    ...campaign,
                    sales: 0,
                    spend: 0
                }
                for (let i = 0; i < 7; i++) {
                    //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                    const day = campaign.performance.find(day => day.dateRange === dayArray[i])
                    //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                    if (day) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            sales: updatedCampaign.sales + day.sales7d,
                            spend: updatedCampaign.spend + day.spend
                        }
                    }
                }
                return updatedCampaign
            })

            const newDataSb = dataSb.map(campaign => {
                let updatedCampaign = {
                    ...campaign,
                    sales: 0,
                    spend: 0
                }
                for (let i = 0; i < 7; i++) {
                    //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                    const day = campaign.performance.find(day => day.dateRange === dayArray[i])
                    //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                    if (day) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            sales: updatedCampaign.sales + day.attributedSales14d,
                            spend: updatedCampaign.spend + day.cost
                        }
                    }
                }
                return updatedCampaign
            })

            const newDataSd = dataSd.map(campaign => {
                let updatedCampaign = {
                    ...campaign,
                    sales: 0,
                    spend: 0
                }
                for (let i = 0; i < 7; i++) {
                    //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                    const day = campaign.performance.find(day => day.dateRange === dayArray[i])
                    //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                    if (day) {
                        updatedCampaign = {
                            ...updatedCampaign,
                            sales: updatedCampaign.sales + day.attributedSales14d,
                            spend: updatedCampaign.spend + day.cost
                        }
                    }
                }
                return updatedCampaign
            })


            let updatedDataSales = {
                ...dataSales,
                sales: 0,
                orders: 0,
                units: 0
            }
            for (let i = 0; i < 7; i++) {
                //performance arrayi içinde bir günü buldu -- bunu bir haftanın 7 günü için tekrarlayacağız
                const day = dataSales.performance.find(day => day.dateRange === dayArray[i])
                //o güne ait veriyi bulduktan sonra bunu mevcut campaign ögesine ekleyeceğiz.
                if (day) {
                    updatedDataSales = {
                        ...dataSales,
                        sales: updatedDataSales.sales + day.totalSales,
                        orders: updatedDataSales.orders + day.orderItemCount,
                        units: updatedDataSales.units + day.unitCount
                    }
                }
            }

            week[j] = {
                totalSales: updatedDataSales.sales, orders: updatedDataSales.orders, units: updatedDataSales.units,
                sales: 0, spend: 0
            }
            newData.forEach(updatedCampaign => {
                week[j].sales = week[j].sales + updatedCampaign.sales
                week[j].spend = week[j].spend + updatedCampaign.spend
            })
            newDataSb.forEach(updatedCampaign => {
                week[j].sales = week[j].sales + updatedCampaign.sales
                week[j].spend = week[j].spend + updatedCampaign.spend
            })
            newDataSd.forEach(updatedCampaign => {
                week[j].sales = week[j].sales + updatedCampaign.sales
                week[j].spend = week[j].spend + updatedCampaign.spend
            })
        }

        tableContent = <PerformanceTable dateArray={dateStrArray} weekData={week}></PerformanceTable>
    }

    return (
        <div>
            {tableContent}
        </div>
    )
}