//import AccountDropdown from "./AccountDropdown";
import patara from './photos/patara.png';
import Link from "./Link";
//import { updateAccount, useFetchUserQuery } from "../store";
//import { useDispatch } from "react-redux";

function Header2() {
    //const dispatch = useDispatch();
    //const { data, error, isFetching } = useFetchUserQuery();

    let renderedLinks;

    const links = [
        { label: 'Home', path: '/' },
        { label: 'Services', path: '/services' },
    ];

    renderedLinks = links.map((link) => {
        return <Link key={link.path} to={link.path} className='mx-4' activeClassName='font-bold border-b-4 border-black-500' >{link.label}</Link>
    });

    return (
        <div className="flex flex-col sticky inset-x-0 top-0">
            <div className="h-[10vh] flex flex-row justify-between items-center px-5 py-2 bg-sky-50" >
                <div className="flex flex-row justify-between items-center">
                    <div className="w-32 h-16" >
                        <a href="/" ><img src={patara} href="/" alt="patara logo" /></a>
                    </div>
                    <div className='mx-10 text-xs' >
                        <h1 className='font-bold' >Patara Consulting</h1>
                        <div className='flex items-center' >
                            <h3>Amazon Consulting Agency</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[4vh] px-5 py-3 flex flex-row items-center bg-sky-50 border-t justify-center" >
                {renderedLinks}
            </div>
        </div>
    );
}

export default Header2;