import { useState } from "react";
import { useSelector } from "react-redux";

import CampaignsDropdown from "../CampaignsDropdown";
import SearchTermsList from "./SearchTermsList";
import SelectAccountDiv from "../SelectAccountDiv"


function SearchTermPage() {
    const accountId = useSelector(state => state.accounts.accountId);
    const [campaignId, setCampaignId] = useState(null);

    return (
        <div>
            {accountId
                ? <div className="flex flex-col h-screen">
                    <div className="flex px-5 py-1">
                        <CampaignsDropdown setCampaignId={setCampaignId} />
                    </div>
                    {campaignId
                        ? <SearchTermsList campaignId={campaignId} />
                        : <div className="m-5 p-2 rounded-xl shadow">Select a campaign to display targeting performance</div>
                    }
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }
        </div>
    );
}

export default SearchTermPage;