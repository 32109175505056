export const GROUPED_COLUMNS = [
    {
        Header: 'Account Name',
        Footer: 'Account Name',
        accessor: 'accountName',
        Cell: ({ row, value }) => {
            return (
                <div className="pl-1 break-words">
                    {value}
                    <div className="text-gray-300 text-xxs">{"Account ID: " + row.original.accountId}</div>
                </div>
            )
        }
    },
    {
        Header: 'Budget',
        Footer: 'Daily Budget',
        accessor: 'dailyBudget',
        Cell: ({ row, value }) => <div>
            <div className='flex justify-center'>{"$" + value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            <div className='flex justify-center text-xxs text-gray-400'>${row.original.spendPerDay.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
        </div>
    },
    {
        Header: 'ACOS',
        Footer: 'ACOS',
        accessor: 'acos_1',
        sortDescFirst: true,
        Cell: ({ value }) => {
            const acos = Number(value);
            return acos > 50
                ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                : (acos < 20 && acos > 0
                    ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                    : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                )
        }
    },
    {
        Header: 'TACOS',
        Footer: 'TACOS',
        accessor: 'tacos_1',
        sortDescFirst: true,
        Cell: ({ value }) => {
            const tacos = Number(value);
            return tacos > 35
                ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{tacos === 0 ? '-' : (value + "%")}</div>
                : (tacos < 15 && tacos > 0
                    ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{tacos === 0 ? '-' : (value + "%")}</div>
                    : <div className='flex justify-center'>{tacos === 0 ? '-' : (value + "%")}</div>
                )
        }
    },
    {
        Header: 'Spend',
        Footer: 'Spend',
        accessor: 'spend_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" :
            "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Orders',
        Footer: 'Orders',
        accessor: 'orders_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" :
            Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Org Sales',
        Footer: 'Org Sales',
        accessor: 'totalSales_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" :
            "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Sales',
        Footer: 'Sales',
        accessor: 'sales_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end font-bold'>{value === 0 ? "-" :
            "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
    },
    {
        Header: 'Clicks',
        Footer: 'Clicks',
        accessor: 'clicks_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'Impress',
        Footer: 'Impress',
        accessor: 'impressions_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
    },
    {
        Header: 'CTR %',
        Footer: 'CTR %',
        accessor: 'ctr_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{value === 0 ? '-' : (value + "%")}</div>
    },
    {
        Header: 'CPC',
        Footer: 'CPC',
        accessor: 'cpc_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
    },
    {
        Header: 'CVR %',
        Footer: 'CVR %',
        accessor: 'cvr_1',
        sortDescFirst: true,
        Cell: ({ value }) => <div className='flex justify-center'>{value === 0 ? '-' : (value + "%")}</div>
    },
]