import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "../Dropdown";

function ParentDropdown({ parentAsins, setSelectedParentAsin }) {
    const [selectedParent, setSelectedParent] = useState(null);
    const accountId = useSelector(state => state.accounts.accountId);

    useEffect(() => {
        setSelectedParent(null);
        setSelectedParentAsin(null);
    }, [accountId, setSelectedParentAsin]);

    const onChange = (item, id) => {
        setSelectedParent(item);
        setSelectedParentAsin(id)
    };

    let portfolioDropdown;

    const dropdownOptions = parentAsins
        .map(parent => {
            return {
                label: parent.skus[0].seller_sku,
                value: parent.asin
            }
        });

    let sortedDropdownOptions = [...dropdownOptions].sort((a, b) => {
        const valueA = a.label;
        const valueB = b.label;
        return valueA.localeCompare(valueB);
    });

    sortedDropdownOptions.unshift({ label: 'All Listings', value: 0 });

    portfolioDropdown = <Dropdown options={sortedDropdownOptions}
        onChange={onChange} className="w-64 text-sm"
        value={selectedParent} defaultText="All Listings" />


    return (
        <div className="flex flex-row h-12">
            <div className="mx-3">{portfolioDropdown}</div>
        </div>
    );
}

export default ParentDropdown;