import Link from "../Link";

function ContactUsPage() {
    return (
        <div className="flex flex-col overflow-scroll h-[68vh] justify-center">
            <div className="flex py-2 my-5 justify-center font-bold text-xl text-white drop-shadow tracking-wider bg-yellow-400">
                <Link key={'/services'} to={'/services'}
                    className='ml-2 text-white'
                    activeClassName='ml-2 text-white' >{'Explore our services!'}
                </Link>
            </div>
            <div className="flex justify-center">
                <div className="flex flex-col h-[50vh] w-[50vh] bg-sky-800 shadow-lg rounded-xl p-5 text-white text-lg justify-around">
                    <div className="font-bold mb-8 flex justify-center">Contact Us</div>
                    <div className="mb-8">Email: admin@consultpatara.com</div>
                    <div>Mailing Address: </div>
                    <div>Yildiztepe Mah. 144.Sk. 10/4</div>
                    <div className="mb-8">Altindag / Ankara</div>
                    <div>Phone Support: +90 534 051 4651</div>
                </div>
            </div>
        </div>
    );
}
export default ContactUsPage;