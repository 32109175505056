import { useState } from "react";
import { useSelector } from "react-redux";
import { FiDownload } from "react-icons/fi";
import Modal from "../Modal";


export default function NoBuyBox({ div, list }) {
    const accountName = useSelector(state => state.accounts.accountName);
    const [showModal, setShowModal] = useState(false)
    const onClick = () => {
        setShowModal(true);
    }

    const onDownloadClick = () => {
        //console.log("downloading the csv file")
        let headers = ['ASIN']
        let listingInfo = list.map(listing => {
            return listing.asin
        })
        let data = [...headers, ...listingInfo].join('\n')
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        const date = new Date().toISOString().split('T')[0]
        const fileName = `No Buy Box Listings ${accountName} ${date}.csv`;
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    function onChange() {
        setShowModal(false);
    };

    let modal = <Modal onChange={onChange} actionBar={<button onClick={() => setShowModal(false)} className="shadow p-2 rounded-lg">Close</button>}>
        <div className="overflow-scroll">
            {div.length > 0 ? div : "No ASINs to Display"}
        </div>
    </Modal>;

    return (
        <div className="flex items-center">
            {showModal && modal}
            <button className="text-xs p-2 break-words rounded-xl bg-blue-400 text-white shadow" onClick={onClick}>{"No Buy Box Listings: " + div.length}</button>
            <button className="text-lg mx-1" onClick={onDownloadClick} disabled={list.length === 0}><FiDownload></FiDownload></button>
        </div>
    )
}