import { useSelector } from "react-redux";


function AcosDisplay({ value, spend }) {
    const target = useSelector(state => state.accounts.targetAcos);
    const avgPrice = useSelector(state => state.accounts.avgPrice);
    const acos = Number(value)
    const targetAcos = Number(target)

    let acosDiv = <div></div>

    if (acos === 0) {
        if (spend > avgPrice) acosDiv = <div className='flex justify-center bg-red-700 text-white rounded-md'>∞%</div>
        else if (spend > avgPrice / 2) acosDiv = <div className='flex justify-center bg-red-500 text-white rounded-md'>∞%</div>
        else if (spend > avgPrice / 5) acosDiv = <div className='flex justify-center bg-yellow-200 rounded-md'>∞%</div>
        else acosDiv = <div className='flex justify-center rounded-md'>-</div>
    }
    else if (acos > targetAcos * 3) acosDiv = <div className='flex justify-center bg-red-700 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 2) acosDiv = <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 1.5) acosDiv = <div className='flex justify-center bg-red-400 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 1.3) acosDiv = <div className='flex justify-center bg-yellow-200 rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos > targetAcos * 1.2) acosDiv = <div className='flex justify-center bg-yellow-100 rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos < targetAcos * 0.4) acosDiv = <div className='flex justify-center bg-green-700 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos < targetAcos * 0.7) acosDiv = <div className='flex justify-center bg-green-600 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else if (acos < targetAcos * 0.85) acosDiv = <div className='flex justify-center bg-green-200 rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
    else acosDiv = <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>

    return acosDiv

}

export default AcosDisplay

//ACOS kuralları
/*
acos > 50
        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
        : (acos < 20 && acos > 0
            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
            : (acos === 0 && row.original.spend_1 > avgPrice/2
                ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '∞%' : (value + "%")}</div>
                : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>)
        )
*/