import "./performanceToggle.css"

function ToggleSwitch({ isWeekly, setIsWeekly }) {

    const onChange = (event) => {
        setIsWeekly(event.target.checked)
    }
    return (
        <label className="switch">
            <input type="checkbox" onChange={onChange} defaultChecked={isWeekly}></input>
            <span className="slider slider2 round"></span>
        </label>
    );

}

export default ToggleSwitch;