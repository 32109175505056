import ReactDOM from 'react-dom';
import { useEffect } from 'react';

function Modal({ onChange, children, actionBar }) {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);
    return ReactDOM.createPortal(
        <div className='text-sm'>
            <div onClick={onChange} className="fixed inset-0 z-20 bg-gray-200 opacity-90" ></div>
            <div className="fixed inset-32 z-30 bg-white p-8 rounded-xl shadow-xl" >
                <div className='flex flex-col justify-between h-full' >
                    {children}
                    <div className='flex justify-end' >
                        {actionBar}
                    </div>
                </div>
            </div>

        </div>,
        document.querySelector('.modal-container')
    );


};

export default Modal;