import { useFetchCampaignsQuery, useFetchCampaignsReportQuery } from "../../store";
import { useSelector } from "react-redux";
import CampaignsTable from './CampaignsTable';
import Skeleton from '../Skeleton';
import Panel from "../Panel";

function CampaignsList({ portfolioId, pickedDate, accountId }) {
    const { data, error, isFetching } = useFetchCampaignsReportQuery(accountId);

    //HER SAYFA AÇILIŞINDA YENİDEN VERİ AL - REFETCH (refetchOnMount/Reconnect/PageFocus)
    const { data: dataCmpList } = useFetchCampaignsQuery(accountId);
    const isEnabledOnly = useSelector(state => state.accounts.isEnableCmpOnly)

    let columns = [{ label: '4 Weeks', content: null }, { label: '8 Weeks', content: null }, { label: '13 Weeks', content: null }];
    let tableContent, performanceContent, finalContent;
    let campaignList = [];
    if (dataCmpList) {
        campaignList = dataCmpList.campaigns.filter(campaign => {
            return campaign.portfolioId === portfolioId.toString();
        }).map(item => {
            return item.campaignId;
        });
        //console.log(campaignList);
    }
    if (isFetching) {
        console.log(".......Fetching now.......")
        finalContent = columns.map((column, index) => {
            return (
                <Skeleton times={1} key={index} className="h-12 w-96 mx-4" />
            );
        })
        tableContent = <Skeleton times={5} className="h-12 w-full" />;
    } else if (error) {
        tableContent = <div>Error!!! Please contact Patara</div>
    } else if (data && dataCmpList) {


        const dateNow = new Date();
        const offset = new Date().getTimezoneOffset()
        let dateArray = [], dateStartArray = [], dateEndArray = [];
        for (let i = 0; i < 90; i++) {
            let start = new Date();
            let end = new Date();
            start.setDate(dateNow.getDate() - (2 + i));
            end.setDate(dateNow.getDate() - (2 + i));
            start = new Date(start.getTime() + ((offset - 360) * 60 * 1000))
            end = new Date(end.getTime() + ((offset - 360) * 60 * 1000))
            dateStartArray[i] = start.toISOString().split('T')[0];
            dateEndArray[i] = end.toISOString().split('T')[0];
            dateArray[i] = dateStartArray[i] + "-" + dateEndArray[i];
            //console.log('Day ', i, ': Start Date: ', dateStartArray[i], ', End Date: ', dateEndArray[i]);
        }

        let dataEnabled, dataFiltered;
        if (portfolioId !== 0) {
            dataFiltered = data.filter(campaign => {
                return campaignList.includes(campaign.campaignId.toString());
            });
        } else {
            dataFiltered = data
        }

        if (isEnabledOnly) {
            dataEnabled = dataFiltered.filter(campaign => {
                return campaign.campaignStatus === "ENABLED";
            });
        } else {
            dataEnabled = dataFiltered
        }

        if (dataEnabled.length > 0) {
            //DATE PICKER ÜZERİNDEN GELEN TARİHLERİ BURADA HESAPLA
            //console.log(pickedDate);
            let startIndex = 0;
            let endIndex = 30;
            startIndex = Math.floor(((dateNow.getTime() - (2 * 1000 * 60 * 60 * 24 - ((offset - 360) * 60 * 1000))) - pickedDate.endDate.getTime()) / (1000 * 60 * 60 * 24));
            endIndex = Math.floor(((dateNow.getTime() - (2 * 1000 * 60 * 60 * 24 - ((offset - 360) * 60 * 1000))) - pickedDate.startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
            //console.log("Start Index: " + startIndex + ", End Index: " + endIndex);

            //console.log(data);
            const tableData = dataEnabled.map(targeting => {
                const camp = dataCmpList.campaigns.find(campaign => campaign.campaignId === targeting.campaignId.toString())

                //console.log(camp.dynamicBidding)
                //DYNAMIC BIDDING BILGILERINI BURADAN EKLEYECEĞIZ
                const placementCount = camp.dynamicBidding.placementBidding.length
                let bidTos = 0
                let bidPdp = 0
                let bidRest = 0
                for (let i = 0; i < placementCount; i++) {
                    if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_TOP') {
                        bidTos = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_PRODUCT_PAGE') {
                        bidPdp = camp.dynamicBidding.placementBidding[i].percentage
                    } else if (camp.dynamicBidding.placementBidding[i].placement === 'PLACEMENT_REST_OF_SEARCH') {
                        bidRest = camp.dynamicBidding.placementBidding[i].percentage
                    }
                }
                //const dynBid1 = camp.dynamicBidding.placementBidding[0].percentage
                //const dynBid2 = camp.dynamicBidding.placementBidding[1].percentage
                //const dynBid3 = camp.dynamicBidding.placementBidding[2].percentage

                let currBudget
                if (camp && camp.budget) {
                    currBudget = camp.budget.budget
                }
                else {
                    console.log("budget ile ilgili sorun oluştu - campaign list")
                    currBudget = targeting.campaignBudgetAmount
                }
                let updatedTargeting = {
                    ...targeting,
                    spend_1: 0,
                    sales_1: 0,
                    sales1_1: 0,
                    sales7_1: 0,
                    sales30_1: 0,
                    impressions_1: 0,
                    clicks_1: 0,
                    orders_1: 0,
                }

                //BURAYA YENİ HESAPLAMA NOKTASI EKLE - SEÇİLEN TARİHİ İÇERECEK
                //DİĞER ZAMAN DİLİMLERİ KALMAK ZORUNDA ÇÜNKÜ ÜST TARAFTAKİ ÖZETİN BİLGİSİ ORADAN GELİYOR. GEREKSİZ NOKTALARI ÇIKARABİLİRİZ

                targeting.performance.forEach(performance => {

                    for (let i = startIndex; i < endIndex; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_1: updatedTargeting.spend_1 + performance.spend,
                                sales_1: updatedTargeting.sales_1 + performance.sales14d,
                                sales1_1: updatedTargeting.sales1_1 + performance.sales1d,
                                sales7_1: updatedTargeting.sales7_1 + performance.sales7d,
                                sales30_1: updatedTargeting.sales30_1 + performance.sales30d,
                                impressions_1: updatedTargeting.impressions_1 + performance.impressions,
                                clicks_1: updatedTargeting.clicks_1 + performance.clicks,
                                orders_1: updatedTargeting.orders_1 + performance.purchases14d,
                            }
                        }
                        //if (targeting.campaignId === 217187174790982)
                        //console.log("Date: " + dateArray[i] + ", Campaign Name: " + targeting.campaignName + ", Spend: " + updatedTargeting.spend_1)
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    spend_4: 0,
                    sales_4: 0,
                    impressions_4: 0,
                    clicks_4: 0,
                    orders_4: 0,
                }

                targeting.performance.forEach(performance => {

                    for (let i = 0; i < 30; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_4: updatedTargeting.spend_4 + performance.spend,
                                sales_4: updatedTargeting.sales_4 + performance.sales14d,
                                impressions_4: updatedTargeting.impressions_4 + performance.impressions,
                                clicks_4: updatedTargeting.clicks_4 + performance.clicks,
                                orders_4: updatedTargeting.orders_4 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    spend_8: updatedTargeting.spend_4,
                    sales_8: updatedTargeting.sales_4,
                    impressions_8: updatedTargeting.impressions_4,
                    clicks_8: updatedTargeting.clicks_4,
                    orders_8: updatedTargeting.orders_4,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 30; i < 60; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_8: updatedTargeting.spend_8 + performance.spend,
                                sales_8: updatedTargeting.sales_8 + performance.sales14d,
                                impressions_8: updatedTargeting.impressions_8 + performance.impressions,
                                clicks_8: updatedTargeting.clicks_8 + performance.clicks,
                                orders_8: updatedTargeting.orders_8 + performance.purchases14d,
                            }
                        }
                    }
                });

                updatedTargeting = {
                    ...updatedTargeting,
                    spend_13: updatedTargeting.spend_8,
                    sales_13: updatedTargeting.sales_8,
                    impressions_13: updatedTargeting.impressions_8,
                    clicks_13: updatedTargeting.clicks_8,
                    orders_13: updatedTargeting.orders_8,
                }

                targeting.performance.forEach(performance => {
                    for (let i = 60; i < 90; i++) {
                        if (performance.dateRange === dateArray[i]) {
                            updatedTargeting = {
                                ...updatedTargeting,
                                spend_13: updatedTargeting.spend_13 + performance.spend,
                                sales_13: updatedTargeting.sales_13 + performance.sales14d,
                                impressions_13: updatedTargeting.impressions_13 + performance.impressions,
                                clicks_13: updatedTargeting.clicks_13 + performance.clicks,
                                orders_13: updatedTargeting.orders_13 + performance.purchases14d,
                            }
                        }
                    }
                });

                return {
                    ...updatedTargeting,
                    cpc_1: (updatedTargeting.spend_1 / updatedTargeting.clicks_1).toFixed(2),
                    cpc_4: (updatedTargeting.spend_4 / updatedTargeting.clicks_4).toFixed(2),
                    cpc_8: (updatedTargeting.spend_8 / updatedTargeting.clicks_8).toFixed(2),
                    cpc_13: (updatedTargeting.spend_13 / updatedTargeting.clicks_13).toFixed(2),
                    acos_1: updatedTargeting.sales_1 === 0 ? 0 : ((updatedTargeting.spend_1 / updatedTargeting.sales7_1) * 100).toFixed(2),
                    acos_4: updatedTargeting.sales_4 === 0 ? 0 : ((updatedTargeting.spend_4 / updatedTargeting.sales_4) * 100).toFixed(2),
                    acos_8: updatedTargeting.sales_8 === 0 ? 0 : ((updatedTargeting.spend_8 / updatedTargeting.sales_8) * 100).toFixed(2),
                    acos_13: updatedTargeting.sales_13 === 0 ? 0 : ((updatedTargeting.spend_13 / updatedTargeting.sales_13) * 100).toFixed(2),
                    ctr_1: updatedTargeting.impressions_1 === 0 ? 0 : ((updatedTargeting.clicks_1 / updatedTargeting.impressions_1) * 100).toFixed(2),
                    ctr_4: updatedTargeting.impressions_4 === 0 ? 0 : ((updatedTargeting.clicks_4 / updatedTargeting.impressions_4) * 100).toFixed(2),
                    ctr_8: updatedTargeting.impressions_8 === 0 ? 0 : ((updatedTargeting.clicks_8 / updatedTargeting.impressions_8) * 100).toFixed(2),
                    ctr_13: updatedTargeting.impressions_13 === 0 ? 0 : ((updatedTargeting.clicks_13 / updatedTargeting.impressions_13) * 100).toFixed(2),
                    spendPerDay: updatedTargeting.spend_1 / (endIndex - startIndex),
                    currBudget,
                    bidPdp,
                    bidRest,
                    bidTos
                };
            })

            let spend0 = 0, sales0 = 0, spend1 = 0, sales1 = 0, spend2 = 0, sales2 = 0, spend3 = 0, sales3 = 0;

            tableData.forEach(item => {
                spend0 = spend0 + item.spend_1;
                sales0 = sales0 + item.sales_1;
                spend1 = spend1 + item.spend_4;
                sales1 = sales1 + item.sales_4;
                spend2 = spend2 + item.spend_8;
                sales2 = sales2 + item.sales_8;
                spend3 = spend3 + item.spend_13;
                sales3 = sales3 + item.sales_13;
            });

            tableContent = (
                <CampaignsTable data={tableData} />
                //<StickyTable data={tableData} />
            );
            const summary0 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend0.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">Sales: ${Number(sales0.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">ACOS: {(spend0 / sales0 * 100).toFixed(2) + "%"}</div>
            </div>
            const summary1 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend1.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">Sales: ${Number(sales1.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">ACOS: {(spend1 / sales1 * 100).toFixed(2) + "%"}</div>
            </div>
            const summary2 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend2.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">Sales: ${Number(sales2.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">ACOS: {(spend2 / sales2 * 100).toFixed(2) + "%"}</div>
            </div>
            const summary3 = <div className="flex flex-row justify-between">
                <div className="mx-3">Spent: ${Number(spend3.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">Sales: ${Number(sales3.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
                <div className="mx-3">ACOS: {(spend3 / sales3 * 100).toFixed(2) + "%"}</div>
            </div>

            columns = [
                { label: 'Selected Date', content: summary0 },
                { label: '30 Days', content: summary1 },
                { label: '60 Days', content: summary2 },
                { label: '90 Days', content: summary3 }
            ];

            performanceContent = columns.map((column, index) => {
                return (
                    <div key={index} className="px-3 py-1 mx-4 shadow rounded-lg text-white bg-sky-800">
                        <div className="mb-2 pb-2 font-bold border-b-2 justify-center flex">{column.label}</div>
                        {column.content}
                    </div>
                );
            })

            finalContent = <Panel >
                <div className="flex flex-row overflow-scroll justify-around">
                    {performanceContent}
                </div>
            </Panel >
        } else {
            finalContent = <Panel >
                <div className="flex flex-row overflow-scroll justify-between">
                    No Active Campaigns to Display
                </div>
            </Panel >
        }
    }

    return (
        <div className="flex flex-col" >
            <div className="flex flex-row px-5 py-1">
                {finalContent}
            </div>
            <div className="mx-5 my-1 text-sm">
                {tableContent}
            </div>
        </div >
    );
}

export default CampaignsList;