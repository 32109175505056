export const GROUPED_COLUMNS = [
    {
        Header: '',
        Footer: '',
        id: 'patara',
        columns: [
            {
                Header: 'Keyword',
                Footer: 'Keyword',
                accessor: 'targeting',
                Cell: ({ row, value }) => {
                    let text = value;
                    let category, brand, rating, prime;
                    if (value.includes('category="')) {
                        const index = value.indexOf('"', 10);
                        text = value.slice(10, index);
                        category = value.slice(10, index);
                        if (value.includes('brand="')) {
                            const indexBrStart = value.indexOf('brand="') + 7;
                            const indexBrEnd = value.indexOf('"', indexBrStart);
                            brand = value.slice(indexBrStart, indexBrEnd);
                        }
                        if (value.includes('rating=')) {
                            const indexRating = value.indexOf('rating=') + 7;
                            rating = value.slice(indexRating);
                        }
                        if (value.includes('prime-shipping-eligible="')) {
                            const indexPrime = value.indexOf('prime-shipping-eligible="') + 25;
                            prime = value.slice(indexPrime, indexPrime + 5);
                        }
                    }

                    return (
                        <div className="pl-1 break-words">
                            {value.includes('asin="')
                                ? <div>
                                    <a href={"https://www.amazon.com/dp/" + value.slice(6, 16)} rel='noreferrer' target="_blank">
                                        <div className='flex hover:text-sky-600 hover:cursor-pointer'>{value.slice(6, 16)}</div>
                                    </a>
                                    <div className="text-xxs text-gray-400">{row.original.keywordId}</div>
                                </div>
                                :
                                value.includes('category="')
                                    ?
                                    <div>
                                        <a href={"https://www.amazon.com/s?k=" + category} rel='noreferrer' target="_blank">
                                            <div className='flex hover:text-sky-600 hover:cursor-pointer'>{text}</div>
                                        </a>
                                        <div className="flex flex-row text-xxs mt-1">
                                            {brand && <div className="mr-3">Brand: {brand}</div>}
                                            {rating && <div>Rating: {rating}</div>}
                                            {prime && <div>Prime: {prime}</div>}
                                        </div>
                                        <div className="text-xxs text-gray-400">{row.original.keywordId}</div>
                                    </div>
                                    :
                                    row.original.matchType === 'EXACT' || row.original.matchType === 'PHRASE' || row.original.matchType === 'BROAD'
                                        ? <div>
                                            <div className='flex items-center'>
                                                <a href={"https://www.amazon.com/s?k=" + value} rel='noreferrer' target="_blank" className='flex hover:text-sky-600'>
                                                    {value}
                                                </a>
                                                <div className="bg-sky-500 text-white rounded-full w-4 h-4 flex justify-center text-xxs ml-2 lowercase font-bold">
                                                    {row.original.matchType.slice(0, 1)}
                                                </div>
                                            </div>
                                            <div className="text-xxs text-gray-400">{row.original.keywordId}</div>
                                        </div>
                                        : <div>
                                            <div className='flex'>{value}</div>
                                            <div className="text-xxs text-gray-400">{row.original.keywordId}</div>
                                        </div>
                            }
                        </div>
                    )
                }
            },
            {
                Header: 'Search Term',
                Footer: 'Search Term',
                accessor: 'searchTerm',
                Cell: ({ value }) => <div className='flex px-2 font-bold'>{value}</div>
            },
        ]
    },
    {
        Header: 'Last Week',
        Footer: 'Last Week',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_1',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_1',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '4 Weeks',
        Footer: '4 Weeks',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_4',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_4',
                id: "spend_4",
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_4',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '8 Weeks',
        Footer: '8 Weeks',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_8',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_8',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '13 Weeks',
        Footer: '13 Weeks',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_13',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_13',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '26 Weeks',
        Footer: '26 Weeks',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_26',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_26',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_26',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_26',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_26',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_26',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_26',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
    {
        Header: '52 Weeks',
        Footer: '52 Weeks',
        columns: [
            {
                Header: 'ACOS',
                Footer: 'ACOS',
                accessor: 'acos_52',
                sortDescFirst: true,
                Cell: ({ value }) => {
                    const acos = Number(value);
                    return acos > 50
                        ? <div className='flex justify-center bg-red-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                        : (acos < 20 && acos > 0
                            ? <div className='flex justify-center bg-green-500 text-white rounded-md'>{acos === 0 ? '-' : (value + "%")}</div>
                            : <div className='flex justify-center'>{acos === 0 ? '-' : (value + "%")}</div>
                        )
                }
            },
            {
                Header: 'Spend',
                Footer: 'Spend',
                accessor: 'cost_52',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Orders',
                Footer: 'Orders',
                accessor: 'orders_52',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Sales',
                Footer: 'Sales',
                accessor: 'sales_52',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : "$" + Number(value.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</div>
            },
            {
                Header: 'Clicks',
                Footer: 'Clicks',
                accessor: 'clicks_52',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{value === 0 ? "-" : Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'Impress',
                Footer: 'Impress',
                accessor: 'impressions_52',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex pr-1 justify-end'>{Number(value.toFixed(0)).toLocaleString()}</div>
            },
            {
                Header: 'CPC',
                Footer: 'CPC',
                accessor: 'cpc_52',
                sortDescFirst: true,
                Cell: ({ value }) => <div className='flex justify-center'>{isNaN(value) ? '-' : "$" + value}</div>
            },
        ]
    },
]