import inventory2 from '../photos/inventory2.png';
import catalog from '../photos/catalog.webp';
import design from '../photos/design.webp';
import customer from '../photos/customer.png';
import ts from '../photos/ts.png';
import ads from '../photos/ads.png';

function ServicesPage() {

    const imgCN = "h-[15vh] w-[15vw] mb-3 rounded-xl overflow-hidden flex";
    const titleCN = "w-[15vw] mt-3 mb-4 font-bold";
    const descCN = "h-[20vh] w-[15vw] text-justify";
    const columnCN = "w-[15vw] flex flex-col items-center";

    const desc = [
        "Patara helps sellers advertise their products with optimized ad spend and revenue. To optimize ad campaigns, we use a long-tested algorithm and optimal data. This algorithm examines all data and generates results based on a variety of factors, such as ad sales, impressions, clicks, and return on ad spend.",
        "One of the most crucial responsibilities that must be performed delicately while selling on Amazon is inventory management. Patara keeps track of your inventory, examines your sales trend and goals, and provides guidance on when to place new product orders.",
        "Catalog management is the process of organizing and managing a catalog of products or services.A vital part of e-commerce businesses, catalog management can include creating and maintaining product descriptions, setting prices, and managing inventory levels.",
        "Patara creates outstanding designs for your product details pages which helps you with the conversion rate by increasing the number of people buying your product once they visit your listing page. We also create amazing A+ content (EBC) and storefronts for our clients.",
        "Ensuring customer satisfaction on Amazon is possible with the service you provide to them as much as the product you sell. By ensuring the satisfaction of your customers, you get good comments and you are protected from negative feedback that affects account health.",
        "One of the biggest challenges on Amazon is fixing problems or creating appeals for violations. With our great experience, we take care of all issues with Amazon while you can focus on your business. Our experts have experience on most of the issues with Amazon including account and listing deactivations."
    ];

    const title = [
        "Advertising Management",
        "Inventory Management & Planning",
        "Catalog Management",
        "Design & SEO",
        "Customer Service",
        "Troubleshooting"
    ];

    const desc1 = "At Patara Consulting, we take care of all Amazon tasks, including the advertisement, catalog management, account health issues, customer service, creative creation, and inventory management. We provide you with full Amazon seller account management so you can focus on running your business. Our main goal is to increase your sales and profit at the same time."


    return (
        <div className="flex flex-col overflow-scroll h-[68vh]">
            <div className="flex justify-center mt-6 mb-3 bg-sky-300 py-2 font-bold text-xl tracking-wider">Patara Account Management Services</div>
            <div className="flex justify-center mb-10 px-96 bg-orange-600 py-2 text-white text-base text-justify">{desc1}</div>
            <div className="py-2 flex flex-row justify-around">
                <div className={columnCN}>
                    <div className={imgCN}>
                        <img src={ads} alt="advertisement" />
                    </div>
                    <div className={titleCN}>{title[0]}</div>
                    <div className={descCN}>{desc[0]}</div>
                </div>
                <div className={columnCN}>
                    <div className={imgCN}>
                        <img src={inventory2} alt="inventory management" />
                    </div>
                    <div className={titleCN}>{title[1]}</div>
                    <div className={descCN}>{desc[1]}</div>
                </div>
                <div className={columnCN}>
                    <div className={imgCN}>
                        <img src={catalog} alt="catalog" />
                    </div>
                    <div className={titleCN}>{title[2]}</div>
                    <div className={descCN}>{desc[2]}</div>
                </div>
                <div className={columnCN}>
                    <div className={imgCN}>
                        <img src={design} alt="design" />
                    </div>
                    <div className={titleCN}>{title[3]}</div>
                    <div className={descCN}>{desc[3]}</div>
                </div>
                <div className={columnCN}>
                    <div className={imgCN}>
                        <img src={customer} alt="customer" />
                    </div>
                    <div className={titleCN}>{title[4]}</div>
                    <div className={descCN}>{desc[4]}</div>
                </div>
                <div className={columnCN}>
                    <div className={imgCN}>
                        <img src={ts} alt="troubleshooting" />
                    </div>
                    <div className={titleCN}>{title[5]}</div>
                    <div className={descCN}>{desc[5]}</div>
                </div>
            </div>
        </div>
    );
}
export default ServicesPage;