import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GoSync } from "react-icons/go";
import { MdBackup } from "react-icons/md";
import { ImCancelCircle, ImCheckmark } from "react-icons/im";
import { FcApproval } from "react-icons/fc";
import { useUpdateBidMutation, updatePataraBids } from "../../store";
import Dropdown from '../Dropdown';

function TargetingTableBulkUpdate({ selectedFlatRows, deselectAll }) {
    const dispatch = useDispatch();
    const [updateBidContentVisibility, setUpdateBidContentVisibility] = useState('main-button');
    const [selectedAction, setSelectedAction] = useState('Set Bid to ($)');
    const [submittable, setSubmittable] = useState(false);
    const [formValue, setFormValue] = useState('');
    const pataraBids = useSelector(state => state.patarabids.pataraBids);
    const accountId = useSelector(state => state.accounts.accountId);
    const [updateBid, response] = useUpdateBidMutation();

    const classname = 'w-14 mr-1 border-r border-t border-b rounded-r-lg px-2 focus:outline-0';
    const [inputClass, setInputClass] = useState(classname);

    const selectedRows = selectedFlatRows.map(row => row.original.keywordId);

    //FORM KIRMIZI İKEN SELECT ALL DİYEMESİN YA DA
    //SUBMIT ALL DERSE HATA VERSIN
    //BU ÇOK ÖNEMLİİİİİİİ

    const selectedKeywords = selectedRows.map(keyword => {
        return pataraBids.find(targeting => {
            return keyword === targeting.id;
        })
    });

    const onUpdateBidClick = () => {
        setUpdateBidContentVisibility('dropdown');
    };

    const onSubmitAllClick = async () => {
        //send keywords array to the server
        //remove pause in production
        await updateBid({ selectedKeywords, accountId });
        const updatedKeywords = selectedKeywords.map(keyword => {
            return {
                ...keyword,
                isSubmitted: true
            }
        })
        dispatch(updatePataraBids(updatedKeywords));
        //if (response.isSuccess) { deselectAll() }
    };

    const onApproveClick = () => {
        if (submittable) {
            let updatedKeywords = [];
            if (selectedAction === 'Set Bid to ($)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: formValue
                    }
                });
            } else if (selectedAction === 'Increase Bid by (%)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: (keyword.bid * (1 + (formValue / 100))).toFixed(2)
                    }
                });
            } else if (selectedAction === 'Decrease Bid by (%)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: (keyword.bid * (1 - (formValue / 100))).toFixed(2)
                    }
                });
            } else if (selectedAction === 'Increase Bid by ($)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    return {
                        ...keyword,
                        bid: (Number(keyword.bid) + Number(formValue)).toFixed(2)
                    }
                });
            } else if (selectedAction === 'Decrease Bid by ($)') {
                updatedKeywords = selectedKeywords.map(keyword => {
                    if (Number(keyword.bid) < Number(formValue)) {
                        return {
                            ...keyword,
                            bid: 0
                        }
                    } else {
                        return {
                            ...keyword,
                            bid: (Number(keyword.bid) - Number(formValue)).toFixed(2)
                        }
                    }
                });
            }
            dispatch(updatePataraBids(updatedKeywords));
            setInputClass(classname);
            setUpdateBidContentVisibility('main-button');
            setFormValue('');
            setSubmittable(false);
        } else {
            setInputClass((classname + ' border-red-500'));
        }
    };

    const onCancelClick = () => {
        setInputClass(classname);
        setUpdateBidContentVisibility('main-button');
        setFormValue('');
    };



    const dropdownOptions = [
        {
            label: 'Set Bid to ($)',
            value: 'setbid',
        },
        {
            label: 'Increase Bid by (%)',
            value: 'increase-perc',
        },
        {
            label: 'Decrease Bid by (%)',
            value: 'decrease-perc',
        },
        {
            label: 'Increase Bid by ($)',
            value: 'increase-amount',
        },
        {
            label: 'Decrease Bid by ($)',
            value: 'decrease-amount',
        },
    ];

    const onChange = (event) => {
        const value = event.target.value;
        if (!value || value === '0' || value.slice(value.length - 1) === '.' || value < 0.1) {
            //setDisabled(true);
            setInputClass((classname + ' border-red-500'));
            setSubmittable(false);
            //make input border red
        } else if (!isNaN(value) && value.length < 6) {
            //setDisabled(false);
            setInputClass((classname + ' border-green-600'));
            setSubmittable(true);
            //make input border gray again
        }
        if (!isNaN(value) && value.length < 6) {
            setFormValue(value);
        }
    };

    return (
        <div className='flex flex-row items-center p-2 bg-white h-16 w-auto'>
            <div className='p-1 mx-1 w-28' >Selected: {selectedFlatRows.length}</div>
            <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-sky-500 text-white rounded-lg'}
                disabled={selectedFlatRows.length === 0 || response.isLoading} onClick={onSubmitAllClick} >
                <div className="flex flex-row items-center">
                    Submit All
                    <div className="ml-1">
                        {response.isLoading ? <GoSync className="animate-spin" /> : (response.isSuccess ? <FcApproval /> : <MdBackup />)}
                    </div>
                </div>
            </button>
            <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-orange-400 text-white rounded-lg'}
                disabled={selectedFlatRows.length === 0} onClick={onUpdateBidClick} hidden={updateBidContentVisibility !== 'main-button'} >
                Update Bid
            </button>
            <div className="flex flex-row"
                style={updateBidContentVisibility !== 'dropdown' ? { display: 'none' } : {}}>
                <Dropdown
                    options={dropdownOptions}
                    value={selectedAction}
                    onChange={(label) => setSelectedAction(label)}
                    className='mx-1 w-48'
                    padding='py-2' />
                <div className="flex flex-row drop-shadow">
                    <div className="flex justify-center bg-gray-50 border-l border-t border-b rounded-l-lg px-1 items-center w-6">
                        {selectedAction === 'Set Bid to ($)' || selectedAction === 'Decrease Bid by ($)' || selectedAction === 'Increase Bid by ($)'
                            ? '$'
                            : '%'}
                    </div>
                    <input
                        className={inputClass}
                        value={formValue}
                        onChange={onChange} >
                    </input>
                </div>
                <button
                    onClick={onApproveClick}
                    className='mx-1'>
                    <ImCheckmark />
                </button>
                <button
                    onClick={onCancelClick}
                    className='mx-1'>
                    <ImCancelCircle />
                </button>
            </div>
            <div className="mx-7">
                <div>{response.data ? response.data.error.length > 0 ? <div className="font-bold text-red-600">{"Error: " + response.data.error.length + " - " + response.data.error}</div> : <div className="text-green-500">Error: 0</div> : "Error: Not submitted yet"}</div>
                <div>{response.data ? response.data.success.length > 0 ? <div className="text-green-500">{"Success: " + response.data.success.length}</div> : <div className="font-bold text-red-600">Success: 0</div> : "Success: Not submitted yet"}</div>
            </div>
        </div>
    );
}

export default TargetingTableBulkUpdate;