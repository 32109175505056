//import { useSelector } from "react-redux";
import CampaignEnableToggle from "../CampaignEnableToggle2"


function TargetingTableBulkUpdate({ selectedFlatRows, deselectAll }) {
    //console.log("campaign bulk update page")
    //const isEnableCmpOnly = useSelector(state => state.accounts.isEnableCmpOnly);
    //console.log(isEnableCmpOnly)

    const onSubmitAllClick = async () => {
        console.log("submit clicked")
    };

    return (
        <div className='flex flex-row items-center p-2 bg-white h-16 w-auto'>
            <div className='p-1 mx-1 w-28' >Selected: {selectedFlatRows.length}</div>
            <button className={selectedFlatRows.length === 0 ? 'p-2 m-2 bg-gray-100 text-gray-400 rounded-lg' : 'p-2 m-2 bg-sky-500 text-white rounded-lg'}
                onClick={onSubmitAllClick} >
                <div className="flex flex-row items-center">
                    Submit All
                </div>
            </button>
            <div className="mx-2"><CampaignEnableToggle></CampaignEnableToggle></div>


        </div>
    );
}

export default TargetingTableBulkUpdate;