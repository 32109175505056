import { useSelector } from "react-redux";
import SelectAccountDiv from "../SelectAccountDiv"
import ListingsList from "./ListingsList3"

export default function ListingPage() {
    const accountId = useSelector(state => state.accounts.accountId);

    return (
        <div>
            {accountId
                ? <div className="my-5">
                    <div className="flex justify-center m-3 py-2 border rounded-xl font-bold shadow">Catalog Listings</div>
                    <div className="p-5"><ListingsList accountId={accountId}></ListingsList></div>
                </div>
                : <SelectAccountDiv></SelectAccountDiv>
            }

        </div>
    )
}