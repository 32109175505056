import { useFetchListingsQuery } from "../../store"
import { useState } from "react";
import { FiDownload } from "react-icons/fi";
import Skeleton from '../Skeleton';
import SameMethod from "./SameMethodList";
import NoBuyBox from "./NoBuyBoxList";
import ParentDropdown from "./ParentDropdown";
import Hijacker from "./HijackerList"

export default function ListingsList({ accountId }) {
    const { data, isFetching } = useFetchListingsQuery({ accountId })
    const [selectedParent, setSelectedParent] = useState(null);

    let listingsDiv = <div className="shadow p-2 rounded-xl border">No Data Received</div>
    let infoDiv = <div></div>
    if (isFetching) {
        listingsDiv = <Skeleton times={10} className="h-10 w-full" />;
    } else if (data) {
        if (data.length > 0) {
            const parentAsins = data.filter(asin => asin.isParent)
            const data1 = data.map((asin) => {
                asin = {
                    ...asin,
                    sameMethod: false
                }
                asin.skus.forEach((sku1, index2) => {
                    if (index2 === 1 && sku1.fulfillment_channel === asin.skus[0].fulfillment_channel) {
                        //console.log("same method: ", asin.asin)
                        asin.sameMethod = true
                    } else if (index2 > 1 && asin.sameMethod) {
                        asin.skus.forEach((sku2, index3) => {
                            if (index3 !== index2 && sku1.fulfillment_channel !== sku2.fulfillment_channel) {
                                //console.log("changed sameMethod: ", asin.asin)
                                asin.sameMethod = false
                            }
                        })
                    }
                })
                return asin
            })
            let newData
            if (selectedParent) {
                newData = data1.filter(asin => asin.parentAsin === selectedParent)
            } else {
                newData = data1
            }
            let sameMethodDiv = [], sameMethodList = [], noBuyBoxDiv = [], noBuyBoxList = [], hijackerDiv = [], hijackerList = []
            listingsDiv = newData.map((asin, index1) => {
                let skusDiv = asin.skus.map((listing, index2) => {
                    return (
                        <div key={index2} className="flex flex-row">
                            <div className="text-xs p-2 basis-15p break-words overflow-hidden ">{listing.seller_sku}</div>
                            <div className="text-xs p-2 basis-7p break-words overflow-hidden flex justify-center ">${listing.price}</div>
                            <div className="text-xs p-1 basis-5p break-words overflow-hidden ">{listing.fulfillment_channel === 'DEFAULT' ? <input readOnly className="w-[4vw] p-1 border rounded-xl" value={listing.quantity}></input> : ""}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden flex justify-center ">{listing.fulfillment_channel === 'DEFAULT' ? "FBM" : "FBA"}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden justify-center ">{listing.status}</div>
                            <div className="text-xs p-2 basis-8p break-words overflow-hidden justify-center">{listing.listing_id}</div>
                        </div>
                    )
                })
                skusDiv.unshift(
                    <div key={-1} className="flex flex-row py-1 bg-white">
                        <div className="flex text-xs p-2 basis-15p justify-center ">SKU</div>
                        <div className="flex text-xs p-2 basis-7p justify-center ">Price</div>
                        <div className="flex text-xs p-2 basis-5p justify-center ">Quantity</div>
                        <div className="flex text-xs p-2 basis-5p justify-center ">Fulfill.</div>
                        <div className="flex text-xs p-2 basis-5p justify-center ">Status</div>
                        <div className="flex text-xs p-2 basis-8p justify-center">Listing Id</div>
                    </div>
                )
                let classNameAsin = "text-xs p-2 basis-7p break-words overflow-hidden"
                const asinDiv =
                    <div key={index1} className="shadow border rounded-xl my-3">
                        <div className="flex flex-row">
                            <div className="text-xs p-2 w-[8vw] max-h-[16vh] rounded-xl overflow-hidden"><img src={asin.main_image} alt="main_image" className="rounded-xl shadow-lg" /></div>
                            <div className={classNameAsin}>
                                <div className="w-fit">
                                    <a href={"https://www.amazon.com/dp/" + asin.asin} rel='noreferrer' target="_blank">
                                        <div className='flex hover:text-sky-600 hover:cursor-pointer'>{asin.asin}</div>
                                    </a>
                                </div>
                            </div>
                            <div className="text-xs p-2 basis-30p text-justify">{asin.item_name}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden"><div>{asin.sales_rank}</div><div className="text-xxs">{asin.sales_rank_title}</div></div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden"><div>{asin.sales_rank_cat}</div><div className="text-xxs">{asin.sales_rank_cat_title}</div></div>
                            <div className="text-xs p-2 basis-6p break-words overflow-hidden">{asin.parentAsin}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden">{asin.item_type_keyword}</div>
                            <div className="text-xs p-2 basis-5p break-words overflow-hidden">{asin.product_type}</div>
                            <div className="text-xxs p-2 basis-6p break-words overflow-hidden">{asin.upc || asin.ean}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.size}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.color}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.headwear_size}</div>
                            <div className="text-xxs p-2 basis-5p break-words overflow-hidden">{asin.headwear_size_to}</div>
                        </div>
                        <div className="border-t">
                            {skusDiv}
                        </div>
                    </div>
                if (asin.sameMethod) {
                    sameMethodDiv.push(asinDiv)
                    sameMethodList.push(asin)
                }
                if (asin.noBuyBox) {
                    noBuyBoxDiv.push(asinDiv)
                    noBuyBoxList.push(asin)
                }
                if (asin.hijacker) {
                    hijackerDiv.push(asinDiv)
                    hijackerList.push(asin)
                }
                return asinDiv
            })
            const onDownloadClick = () => {
                //console.log("downloading the csv file")
                let headers = ['', 'ASIN', 'UPC', 'EAN', 'SIZE', 'COLOR', 'TYPE', 'CATEGORY', 'SKUS']
                let listingInfo = newData.map(listing => {
                    return { asin: listing.asin, upc: listing.upc, ean: listing.ean, size: listing.size, color: listing.color, product_type: listing.product_type, item_type_keyword: listing.item_type_keyword, skus: listing.skus }
                })
                let data1 = []

                for (let i = 0; i < headers.length; i++) {
                    if (i === headers.length - 1) {
                        data1.push(headers[i])
                        data1.push('\n')
                    }
                    else data1.push(headers[i])
                }
                for (let i = 0; i < listingInfo.length; i++) {
                    data1.push(listingInfo[i].asin)
                    data1.push(listingInfo[i].upc)
                    data1.push(listingInfo[i].ean)
                    data1.push(listingInfo[i].size)
                    data1.push(listingInfo[i].color)
                    data1.push(listingInfo[i].product_type)
                    data1.push(listingInfo[i].item_type_keyword)
                    listingInfo[i].skus.forEach(sku => {
                        data1.push(sku.seller_sku)
                        data1.push(sku.fulfillment_channel)
                    })
                    data1.push('\n')
                }
                //console.log(data1)

                const url = window.URL.createObjectURL(new Blob([data1]))
                const link = document.createElement('a')
                link.href = url
                const date = new Date().toISOString().split('T')[0]
                const fileName = `Listings ${accountId} ${date}.csv`;
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
            }
            infoDiv = <div className="flex flex-row py-2 items-center">
                <div className="text-xs p-2 break-words overflow-hidden">{"Number of Listings: " + data.length}</div>
                <SameMethod div={sameMethodDiv} list={sameMethodList} ></SameMethod>
                <NoBuyBox div={noBuyBoxDiv} list={noBuyBoxList}></NoBuyBox>
                <Hijacker div={hijackerDiv} list={hijackerList}></Hijacker>
                <ParentDropdown parentAsins={parentAsins} setSelectedParentAsin={setSelectedParent}></ParentDropdown>

                <div className="flex grow justify-end items-center">
                    {selectedParent ? <div>ASINs: {newData.length}</div> : <div>ASINs: {data.length}</div>}
                    <button className="text-lg mx-2 flex" onClick={onDownloadClick} disabled={listingsDiv.length === 0}><FiDownload></FiDownload></button>
                </div>

            </div>
        }
        else {
            listingsDiv = <div className="shadow p-2 rounded-xl border">No Data Received from Database</div>
        }
    }

    return (
        <div className="min-h-[50vh]">
            <div className="z-10 sticky top-0 bg-white">
                {infoDiv}
                <div className="flex flex-row font-bold py-3 bg-white shadow rounded-xl border">
                    <div className="flex text-s p-2 basis-8p justify-center border-x">Main Image</div>
                    <div className="flex text-s p-2 basis-7p justify-center border-x">ASIN</div>
                    <div className="flex text-s p-2 basis-30p justify-center border-x">Title</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">BSR</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">BSR Cat</div>
                    <div className="flex text-s p-2 basis-6p justify-center border-x">Parent</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Category</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Type</div>
                    <div className="flex text-s p-2 basis-6p justify-center border-x">UPC/EAN</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Size</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">Color</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">H Size</div>
                    <div className="flex text-s p-2 basis-5p justify-center border-x">H Sizeto</div>
                </div>
            </div>
            <div className="max-h-[80vh] overflow-scroll ">
                {listingsDiv}
            </div>
        </div>
    )
}